import { Injectable } from "@angular/core";
import { DBService } from "./stitch-service.service";

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  constructor(private readonly dbService: DBService) { }

  async createCity(city: string) {
    try {
      const existingCity = await this.dbService.localGeneralDB.findOne({ selector: { type: 'city', itemName: city } }).exec();
      if (!existingCity) {
        await this.dbService.localGeneralDB.insert({ itemName: city, type: 'city' });
      }
    } catch (error) {
      console.error('Error creating city:', error);
    }
  }

  async createCommunity(community: string) {
    try {
      const existingCommunity = await this.dbService.localGeneralDB.findOne({ selector: { type: 'community', itemName: community } }).exec();
      if (!existingCommunity) {
        await this.dbService.localGeneralDB.insert({ itemName: community, type: 'community' });
      }
    } catch (error) {
      console.error('Error creating community:', error);
    }
  }
}
