<app-nav-bar dir="rtl"></app-nav-bar>
<router-outlet></router-outlet>
<app-status-strip></app-status-strip>

<!-- Permission Dialog -->
<div *ngIf="showPermissionDialog" class="permission-dialog-overlay">
  <div class="permission-dialog">
    <h2>{{ permissionDialogTitle }}</h2>
    <p>{{ permissionDialogMessage }}</p>
    <div class="permission-dialog-buttons">
      <button (click)="onPermissionDialogConfirm()">כן</button>
      <button (click)="onPermissionDialogCancel()">לא</button>
    </div>
  </div>
</div>
