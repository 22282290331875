<app-note-dialog-output *ngIf="dialogContent !== null" (closeDialog)="closeDialog()" [content]="dialogContent">
</app-note-dialog-output>
<div class="page-wrapper">
  <div class="writer-details-wrapper page-grid" *ngIf="writer">
    <div class="top-section">
      <i *ngIf="!writer.profileImage" class="icofont-user-alt-7"></i>
      <img *ngIf="writer.profileImage" [src]="writer.profileImage" alt="" class="profile-img">
      <div *ngIf="writer.note" (click)="openDialog($event, writer.note)" class="comment-icon-absolute">
        <i class="icofont-exclamation-circle"></i>
      </div>
      <div class="name-wrapper">
        <p class="writer-name">{{ writer.lastName }} {{ writer.firstName }}</p>
        <p class="writer-location" *ngIf="writer.street && writer.city">{{ writer.street }}, {{ writer.city }}
        </p>
      </div>
      <button type="button" (click)="editWriter()" class="edit-button">ערוך</button>
    </div>
    <div class="basic-details-section">
      <a [href]="'tel:' + addAreaCodeForIsraliNumbers(writer.telephone)" class="basic-detail .inner-grid" *ngIf="writer.telephone">
        <i class="icofont-phone"></i>
        <div>
          <div dir="ltr" class="information">{{ writer.telephone | phone }}</div>
          <div class="description">טלפון</div>
        </div>
      </a>
      <a [href]="'tel:' + addAreaCodeForIsraliNumbers(writer.secondTelephone)" class="basic-detail .inner-grid" *ngIf="writer.secondTelephone">
        <i class="icofont-phone"></i>
        <div>
          <div dir="ltr" class="information">{{ writer.secondTelephone | phone }}</div>
          <div class="description">טלפון נוסף</div>
        </div>
      </a>
      <div class="basic-detail .inner-grid" *ngIf="writer.city">
        <i class="icofont-location-pin"></i>
        <div>
          <div class="information">
            <div>
              <a href="http://maps.google.com/?q={{ writerAddress }}">{{ writer.street }} {{ writer.streetNumber }} <span *ngIf="writer.apartmentNumber">דירה {{ writer.apartmentNumber }}</span> {{ writer.city }}</a>
            </div>
          </div>
          <div class="description">כתובת</div>
        </div>
      </div>
      <a href="mailto:{{writer.email}}" class="basic-detail .inner-grid" *ngIf="writer.email">
        <i class="icofont-envelope"></i>
        <div>
          <div class="information">{{ writer.email }}</div>
          <div class="description">אימייל</div>
        </div>
      </a>
      <div class="basic-detail .inner-grid" *ngIf="writer.isAppropriate">
        <i class="icofont-check-alt"></i>
        <div>
          <div class="information">{{ writer.isAppropriate.level }}</div>
        </div>
      </div>
      <div class="basic-detail .inner-grid" *ngIf="writer.startDate.hebrewDateInWords">
        <i class="icofont-ui-calendar"></i>
        <div>
          <div class="description">תאריך התחלה</div>
          <div class="information">{{ writer.startDate.hebrewDateInWords }}</div>
        </div>
      </div>
    </div>
    <div class="extra-details-section">
      <div *ngIf="writer.pricesDeatails.priceForTorahScroll.price || writer.pricesDeatails.priceForMezuzah.price || writer.pricesDeatails.priceForTefillin.price || writer.pricesDeatails.priceForMegilla?.price" class="section" (click)="closeMenus('pricesDeatails')">
        <div class="section-title-btn">מחירים</div>
        <div class="internal-details-wrapper" [ngClass]="{'closedMenu': !openMenuStatus.pricesDeatails}">
          <div *ngIf="writer.pricesDeatails.priceForTorahScroll.price" class="price-extra-detail">
            <div class="detail-title description">מחיר לעמוד ($)</div>
            <div class="detail">{{ priceForTorahScroll.pricePerPage | currencyConverter:'$' | async }}</div>
            <div class="detail-title description">מחיר לעמוד (₪)</div>
            <div class="detail">{{ priceForTorahScroll.pricePerPage | currencyConverter:'₪' | async }}</div>
            <div *ngIf="writer.pricesDeatails.priceForTorahScroll.worthIt.toString()" class="shave description">שווה לדעתי</div>
            <div>
              <i [class.icofont-check-alt]="writer.pricesDeatails.priceForTorahScroll.worthIt.toString() === 'true'" [class.icofont-close-line]="writer.pricesDeatails.priceForTorahScroll.worthIt.toString() === 'false'"></i>
            </div>
            <div *ngIf="writer.pricesDeatails.priceForTorahScroll.note" (click)="openDialog($event, writer.pricesDeatails.priceForTorahScroll.note)" class="comment-icon">
              <i class="icofont-exclamation-circle"></i>
            </div>
          </div>
          <div *ngIf="writer.pricesDeatails.priceForTorahScroll.price" class="price-extra-detail">
            <div class="detail-title description">לספר תורה ($)</div>
            <div class="detail">{{ priceForTorahScroll.priceForScroll | currencyConverter:'$' | async }}</div>
            <div class="detail-title description">לספר תורה (₪)</div>
            <div class="detail">{{ priceForTorahScroll.priceForScroll | currencyConverter:'₪' | async }}</div>
            <div *ngIf="writer.pricesDeatails.priceForTorahScroll.worthIt.toString()" class="shave description">שווה לדעתי</div>
            <div>
              <i [class.icofont-check-alt]="writer.pricesDeatails.priceForTorahScroll.worthIt.toString() === 'true'" [class.icofont-close-line]="writer.pricesDeatails.priceForTorahScroll.worthIt.toString() === 'false'"></i>
            </div>
            <div *ngIf="writer.pricesDeatails.priceForTorahScroll.note" (click)="openDialog($event, writer.pricesDeatails.priceForTorahScroll.note)" class="comment-icon">
              <i class="icofont-exclamation-circle"></i>
            </div>
          </div>
          <div class="price-extra-detail" *ngIf="writer.pricesDeatails.priceForMezuzah.price">
            <div class="detail-title description">מחיר למזוזה ($)</div>
            <div class="detail">{{ writer.pricesDeatails.priceForMezuzah | currencyConverter:'$' | async }}</div>
            <div class="detail-title description">מחיר למזוזה (₪)</div>
            <div class="detail">{{ writer.pricesDeatails.priceForMezuzah | currencyConverter:'₪'| async }}</div>
            <div *ngIf="writer.pricesDeatails.priceForMezuzah.worthIt.toString()" class="shave description">שווה לדעתי</div>
            <div>
              <i [class.icofont-check-alt]="writer.pricesDeatails.priceForMezuzah.worthIt.toString() === 'true'" [class.icofont-close-line]="writer.pricesDeatails.priceForMezuzah.worthIt.toString() === 'false'"></i>
            </div>
            <div *ngIf="writer.pricesDeatails.priceForMezuzah.note" (click)="openDialog($event, writer.pricesDeatails.priceForMezuzah.note)" class="comment-icon"><i class="icofont-exclamation-circle"></i></div>
          </div>
          <div class="price-extra-detail" *ngIf="writer.pricesDeatails.priceForTefillin.price">
            <div class="detail-title description">מחיר לתפילין ($)</div>
            <div class="detail">{{ writer.pricesDeatails.priceForTefillin | currencyConverter:'$' | async }}</div>
            <div class="detail-title description">מחיר לתפילין (₪)</div>
            <div class="detail">{{ writer.pricesDeatails.priceForTefillin | currencyConverter:'₪' | async }}</div>
            <div *ngIf="writer.pricesDeatails.priceForTefillin.worthIt.toString()" class="shave description">שווה לדעתי</div>
            <div>
              <i [class.icofont-check-alt]="writer.pricesDeatails.priceForTefillin.worthIt.toString() === 'true'" [class.icofont-close-line]="writer.pricesDeatails.priceForTefillin.worthIt.toString() === 'false'"></i>
            </div>
            <div *ngIf="writer.pricesDeatails.priceForTefillin.note" (click)="openDialog($event, writer.pricesDeatails.priceForTefillin.note)" class="comment-icon"><i class="icofont-exclamation-circle"></i></div>
          </div>
          <div class="price-extra-detail" *ngIf="writer.pricesDeatails.priceForMegilla?.price">
            <div class="detail-title description">מחיר למגילה ($)</div>
            <div class="detail">{{ writer.pricesDeatails.priceForMegilla | currencyConverter:'$' | async }}</div>
            <div class="detail-title description">מחיר למגילה (₪)</div>
            <div class="detail">{{ writer.pricesDeatails.priceForMegilla | currencyConverter:'₪' | async }}</div>
            <div *ngIf="writer.pricesDeatails.priceForMegilla?.worthIt.toString()" class="shave description">שווה לדעתי</div>
            <div>
              <i [class.icofont-check-alt]="writer.pricesDeatails.priceForMegilla?.worthIt.toString() === 'true'" [class.icofont-close-line]="writer.pricesDeatails.priceForTefillin.worthIt.toString() === 'false'"></i>
            </div>
            <div *ngIf="writer.pricesDeatails.priceForMegilla?.note" (click)="openDialog($event, writer.pricesDeatails.priceForMegilla?.note)" class="comment-icon"><i class="icofont-exclamation-circle"></i></div>
          </div>
        </div>
      </div>
      <div class="section" (click)="closeMenus('writingDeatails')">
        <div class="section-title-btn">הכתב</div>
        <div class="internal-details-wrapper" [ngClass]="{ closedMenu: !openMenuStatus.writingDeatails }">
          <div *ngIf="writer.writingDeatails.writingLevel.level" class="writing-extra-detail">
            <div class="detail-title description">דרגת כתיבה</div>
            <div class="detail">{{ writer.writingDeatails.writingLevel.level }}</div>
            <div *ngIf="writer.writingDeatails.writingLevel.note" (click)="openDialog($event, writer.writingDeatails.writingLevel.note)" class="comment-icon">
              <i class="icofont-exclamation-circle"></i>
            </div>
          </div>
          <div class="writing-extra-detail" *ngIf="writer.writingDeatails.stabilityLevel.level">
            <div class="detail-title description">יציבות</div>
            <div class="detail">{{ writer.writingDeatails.stabilityLevel.level }}</div>
            <div *ngIf="writer.writingDeatails.stabilityLevel.note" (click)="openDialog($event, writer.writingDeatails.stabilityLevel.note)" class="comment-icon"><i class="icofont-exclamation-circle"></i></div>
          </div>

          <div *ngIf="writer.writingDeatails.eraseLevel.level" class="writing-extra-detail">
            <div class="detail-title description">מחיקה</div>
            <div class="detail">{{ writer.writingDeatails.eraseLevel.level }}</div>
            <div *ngIf="writer.writingDeatails.eraseLevel.note" (click)="openDialog($event, writer.writingDeatails.eraseLevel.note)" class="comment-icon">
              <i class="icofont-exclamation-circle"></i>
            </div>
          </div>

          <div class="writing-extra-detail" *ngIf="thereAreDetailsInGivenObject(writer.writingDeatails.letterSizes)">
            <div class="detail-title description">גודל הכתב</div>
            <div class="detail">
              {{ getLetterSizesString(writer.writingDeatails.letterSizes) }}
            </div>
            <div *ngIf="writer.writingDeatails.letterSizes.note" (click)="openDialog($event, writer.writingDeatails.letterSizes.note)" class="comment-icon">
              <i class="icofont-exclamation-circle"></i>
            </div>
          </div>
          <div class="writing-extra-detail" *ngIf="thereAreDetailsInGivenObject(writer.writingDeatails.writingTypes.types)">
            <div class="detail-title description">סוג הכתב</div>
            <div class="detail">
              {{ writer.writingDeatails.writingTypes.types.ari ? 'אר"י' : '' }}{{ writer.writingDeatails.writingTypes.types.Welish ? ', וועליש' : ''}}{{ writer.writingDeatails.writingTypes.types.beitYosef ? ', בית יוסף' : '' }}
            </div>
          </div>
        </div>
      </div>

      <div class="section" (click)="closeMenus('additionalDeatails')" *ngIf="
              writer.additionalDetails.validCertificate.boolean ||
              writer.additionalDetails.writerLevel.level ||
              writer.additionalDetails.beginnerWriter.boolean ||
              writer.additionalDetails.goesToKotel.boolean ||
              writer.additionalDetails.voatsInElection.boolean ||
              writer.additionalDetails.hasWritenKabala.boolean ||
              writer.additionalDetails.hasWritenBefore.boolean ||
              writer.placeOfWriting.place ||
              writer.communityDeatails.community ||
              writer.lengthOfWritingBook?.length
            ">
        <div class="section-title-btn">פרטים נוספים</div>
        <div class="internal-details-wrapper" [ngClass]="{ closedMenu: !openMenuStatus.additionalDeatails }">
          <div class="writing-extra-detail" *ngIf="writer.communityDeatails.community">
            <div class="detail-title description">קהילה</div>
            <div class="detail">{{ writer.communityDeatails.community }}</div>
            <div *ngIf="writer.communityDeatails.note" (click)="openDialog($event, writer.communityDeatails.note)" class="comment-icon">
              <i class="icofont-exclamation-circle"></i>
            </div>
          </div>
          <div class="writing-extra-detail" *ngIf="writer.placeOfWriting.place">
            <div class="detail-title description">מקום כתיבה</div>
            <div class="detail">{{ writer.placeOfWriting.place }}</div>
            <div *ngIf="writer.placeOfWriting.place === 'חדר'" (click)="writersInRoomList($event)" class="people-icon">
              <img src="assets/icons/people.png" alt="" />
            </div>
            <div *ngIf="writer.placeOfWriting.note" (click)="openDialog($event, writer.placeOfWriting.note)" class="comment-icon">
              <i class="icofont-exclamation-circle"></i>
            </div>
          </div>
          
          <div class="writing-extra-detail" *ngIf="writer.additionalDetails.validCertificate.boolean">
            <div class="detail-title description">תעודה בתוקף</div>
            <div>
              <i [class.icofont-check-alt]="writer.additionalDetails.validCertificate.boolean === 'true'" [class.icofont-close-line]="writer.additionalDetails.validCertificate.boolean === 'false'"></i>
            </div>
            <div *ngIf="writer.additionalDetails.validCertificate.note" (click)="openDialog($event, writer.additionalDetails.validCertificate.note)" class="comment-icon">
              <i class="icofont-exclamation-circle"></i>
            </div>
          </div>

          <div class="writing-extra-detail" *ngIf="writer.additionalDetails.hasWritenBefore.boolean">
            <div class="detail-title description">כתב ספרים בעבר</div>
            <div>
              <i [class.icofont-check-alt]="writer.additionalDetails.hasWritenBefore.boolean === 'true'" [class.icofont-close-line]="writer.additionalDetails.hasWritenBefore.boolean === 'false'"></i>
            </div>
            <div *ngIf="writer.additionalDetails.hasWritenBefore.note" (click)="openDialog($event, writer.additionalDetails.hasWritenBefore.note)" class="comment-icon">
              <i class="icofont-exclamation-circle"></i>
            </div>
          </div>

          <div class="writing-extra-detail" *ngIf="writer.additionalDetails.hasWritenKabala.boolean">
            <div class="detail-title description">כתב קבלה</div>
            <div>
              <i [class.icofont-check-alt]="writer.additionalDetails.hasWritenKabala.boolean === 'true'" [class.icofont-close-line]="writer.additionalDetails.hasWritenKabala.boolean === 'false'"></i>
            </div>
            <div *ngIf="writer.additionalDetails.hasWritenKabala.note" (click)="openDialog($event, writer.additionalDetails.hasWritenKabala.note)" class="comment-icon">
              <i class="icofont-exclamation-circle"></i>
            </div>
          </div>

          <div class="writing-extra-detail" *ngIf="writer.additionalDetails.voatsInElection.boolean">
            <div class="detail-title description">בחירות</div>
            <div>
              <i [class.icofont-check-alt]="writer.additionalDetails.voatsInElection.boolean === 'true' " [class.icofont-close-line]="writer.additionalDetails.voatsInElection.boolean === 'false'"></i>
            </div>
            <div *ngIf="writer.additionalDetails.voatsInElection.note" (click)="openDialog($event, writer.additionalDetails.voatsInElection.note)" class="comment-icon">
              <i class="icofont-exclamation-circle"></i>
            </div>
          </div>

          <div class="writing-extra-detail" *ngIf="writer.additionalDetails.goesToKotel.boolean">
            <div class="detail-title description">הולך לכותל</div>
            <div>
              <i [class.icofont-check-alt]="writer.additionalDetails.goesToKotel.boolean === 'true'" [class.icofont-close-line]="writer.additionalDetails.goesToKotel.boolean === 'false'"></i>
            </div>
            <div *ngIf="writer.additionalDetails.goesToKotel.note" (click)="openDialog($event, writer.additionalDetails.goesToKotel.note)" class="comment-icon">
              <i class="icofont-exclamation-circle"></i>
            </div>
          </div>

          <div class="writing-extra-detail" *ngIf="writer.additionalDetails.beginnerWriter.boolean">
            <div class="detail-title description">סופר מתחיל</div>
            <div>
              <i [class.icofont-check-alt]="writer.additionalDetails.beginnerWriter.boolean === 'true'" [class.icofont-close-line]="writer.additionalDetails.beginnerWriter.boolean === 'false'"></i>
            </div>
            <div *ngIf="writer.additionalDetails.beginnerWriter.note" (click)="openDialog($event, writer.additionalDetails.beginnerWriter.note)" class="comment-icon">
              <i class="icofont-exclamation-circle"></i>
            </div>
          </div>
          <div class="writing-extra-detail" *ngIf="writer.additionalDetails.writerLevel.level">
            <div class="detail-title description">דרגת סופר</div>
            <div class="detail">{{ writer.additionalDetails.writerLevel.level }}</div>
            <div *ngIf="writer.additionalDetails.writerLevel.note" (click)="openDialog($event, writer.additionalDetails.writerLevel.note)" class="comment-icon">
              <i class="icofont-exclamation-circle"></i>
            </div>
          </div>
          <div class="writing-extra-detail" *ngIf="writer.lengthOfWritingBook?.length">
            <div class="detail-title description">אורך כתיבת ספר</div>
            <div class="detail">{{ writer.lengthOfWritingBook?.length }}</div>
            <div *ngIf="writer.lengthOfWritingBook?.note" (click)="openDialog($event, writer.lengthOfWritingBook?.note)" class="comment-icon">
              <i class="icofont-exclamation-circle"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="section" (click)="closeMenus('images')" *ngIf="writer.photos.length">
        <div class="section-title-btn">תמונות</div>
        <div [ngClass]="{ closedMenu: !openMenuStatus.images }">
          <div (click)="preventDefaultAndStopPropagation($event)" *ngFor="let photo of writer.photos_620x620; let i = index" class="photo-wrapper">
            <img *ngIf="photo" style="z-index: 1" class="icon-share" src="assets/icons/share.png" (click)="shareButton(writer.photos[i])" alt="">
            <img *ngIf="photo" appDoubleTapOpenImg [base64Img]="writer.photos[i]" [src]="sanitizer.bypassSecurityTrustResourceUrl(photo)" (error)="errorEventCall(photo, i)" class="photo-of-writing" />
          </div>
        </div>
      </div>

      <div class="section" (click)="closeMenus('recordings')" *ngIf="writer.recordings.length">
        <div class="section-title-btn">תזכורת קולית</div>
        <div [ngClass]="{'closedMenu': !openMenuStatus.recordings}">
          <div (click)="preventDefaultAndStopPropagation($event)" *ngFor="let recording of writer.recordings">
            <app-audio-html5 *ngIf="recording" [recordingUrl]="recording"></app-audio-html5>
          </div>
        </div>
      </div>
      <div class="date-stamp-wrapper">
        <div *ngIf="writer.creationDate" class="date-stamp"> Creation Date: {{writer.creationDate | date: 'medium'}} </div>
        <div *ngIf="writer.editDate" class="date-stamp"> Edit Date: {{writer.editDate | date:'medium'}}</div>
        <div *ngIf="writer.editorUserName" class="date-stamp"> edited by: {{writer.editorUserName}}</div>
      </div>
    </div>
  </div>
</div>
