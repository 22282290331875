<div [formGroup]="parentForm" class="date-inner-grid">
  <div class="section-title">{{ sectionTitle }}</div>
  <div class="input-wrapper">
    <div class="date-button" (click)="d.toggle()" class="date-button">
      <div class="calendar-box">
        <i class="icofont-ui-calendar"></i>
      </div>
    </div>
    <div class="input-container" [formGroupName]="formGroupName">
      <input class="date-input" type="text" ngbDatepicker #d="ngbDatepicker" (dateSelect)="onDateSelect($event)" />
      <input formControlName="hebrewDateInWords" class="date-input" [placeholder]="sectionTitle">
    </div>
  </div>
</div>