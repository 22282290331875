<div class="count-section grid">
  <div (click)="goToReportPage()" class="display-all-btn button"><i class="icofont-list"></i></div>
  <div *ngIf="searchResult" class="count">כמות ברשימה {{ searchResult.length }}</div>
</div>
<div class="page-grid">
  <div *ngIf="location.path() === locationPath.WRITERS_ADVANCED_SEARCH_RESULT && isWriterArray(searchResult)">
    <app-writer-list-item *ngFor="let item of searchResult" [writer]="item"></app-writer-list-item>
  </div>
  <div *ngIf="location.path() === locationPath.BOOKS_ADVANCED_SEARCH_RESULT && !isWriterArray(searchResult)">
    <app-book-list-item *ngFor="let item of searchResult" [book]="item"></app-book-list-item>
  </div>
  <button type="button" class="add-sofer-button" (click)="newSearch()">חיפוש חדש</button>
</div>