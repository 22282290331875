import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { Writer } from '../../interfaces';
import { Store, select } from '@ngrx/store';
import { State } from '../../reducers';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { SearchService } from '../../search.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DBService } from 'src/app/stitch-service.service';
import { LocationPath } from 'src/app/enums';
import { Location } from '@angular/common';
import { sortByDate, sortByLetters } from 'src/app/utils/utils';
import { ScrollService } from 'src/app/scroll.service';
import { ReportsService } from 'src/app/report-page/reports.service';
import { WriterService } from 'src/app/writer.service';

@Component({
  selector: 'app-writers-list-screen',
  templateUrl: './writers-list-screen.component.html',
  styleUrls: ['./writers-list-screen.component.css']
})
export class WritersListScreenComponent implements OnInit, OnDestroy {
  sortButtonText: string;
  writersToDisplay: Writer[] = [];
  writersList: Writer[];
  writersList$Subscription: Subscription;
  writersList$: Observable<Writer[]> = this.store$.pipe(
    select('writers', 'writersList')
  );
  citiesList: string[];
  communitiesList: string[];
  searchForm: UntypedFormGroup;
  searchFormInitialValue: any;
  getWritersFunction: () => Promise<void>;
  isLoading = false;

  constructor(
    private readonly store$: Store<State>,
    private readonly searchService: SearchService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly pouchDbService: DBService,
    private readonly writerService: WriterService,
    private readonly location: Location,
    private readonly scrollService: ScrollService,
    private readonly reportsService: ReportsService,
    private readonly router: Router,
  ) { }

  async ngOnInit(): Promise<void> {
    this.searchForm = new UntypedFormGroup({
      city: new UntypedFormControl(''),
      community: new UntypedFormControl(''),
      hasWritenBefore: new UntypedFormControl(true),
      hasNotWritenBefore: new UntypedFormControl(true),
      isWritingRegularly: new UntypedFormGroup({
        writingRegularly: new UntypedFormControl(true),
        notWritingRegularly: new UntypedFormControl(true),
      }),
      isAppropriate: new UntypedFormGroup({
        bad: new UntypedFormControl(false),
        good: new UntypedFormControl(true),
        veryGood: new UntypedFormControl(true),
      }),
      quickSearch: new UntypedFormControl(''),
      sortedByLetters: new UntypedFormControl(false)
    });

    this.pouchDbService.getCities()
      .then(citiesDoc => {
        this.citiesList = citiesDoc.map(cityDoc => cityDoc.itemName);
      });

    this.pouchDbService.getCommunities()
      .then(communitiesDoc => {
        this.communitiesList = communitiesDoc.map(communityDoc => communityDoc.itemName);
      });

    this.searchFormInitialValue = this.searchForm.value;

    if (this.location.path().split('?')[0] === LocationPath.WRITERS_IN_ROOM_LIST) {
      this.getWritersFunction = async () => {
        const city = this.activatedRoute.snapshot.queryParamMap.get('city');
        const street = this.activatedRoute.snapshot.queryParamMap.get('street');
        const streetNumber = this.activatedRoute.snapshot.queryParamMap.get('streetNumber');
        const writers = await this.writerService.getWritersInRoom(city, street, streetNumber)
        this.writersList = writers;
      }
      await this.getWritersFunction();
    } else if (this.location.path() === LocationPath.WRITERS_LIST_SCREEN) {
      this.getWritersFunction = async () => {
        await new Promise(resolve => setTimeout(resolve, 100));
        const writers = await this.writerService.getWriters();
        this.writersList = writers as Writer[];
      }
      await this.getWritersFunction();
    }
    if (localStorage.getItem('UseWriterListFilterParams') === 'true') {
      this.searchForm.patchValue(JSON.parse(localStorage.getItem('writerListFilterParams')));
      await new Promise(resolve => setTimeout(resolve, 100));
      const writers = await this.writerService.getWriters();
      this.writersList = writers as Writer[];
      this.search()
    }
    this.sortList();
    setTimeout(() => {
      this.scrollService.scroll();
    }, 0);
    localStorage.setItem('UseWriterListFilterParams', 'false');
  }

  onKeyUpSearchByName(event) {
    this.writersToDisplay = this.writersList.filter(writer =>
      writer.firstName.includes(event.target.value) || writer.lastName.includes(event.target.value)
    );
  }

  async resetSearch() {
    this.isLoading = true;
    await this.getWritersFunction();
    this.sortList();
    this.searchForm.reset(this.searchFormInitialValue);
    this.isLoading = false;
  }

  async search() {
    localStorage.setItem('writerListFilterParams', JSON.stringify(this.searchForm.value));
    this.writersList = await this.searchService.writerListFilter(this.searchForm.value) as Writer[];
    this.sortList();
  }

  sortList() {
    const sortListByLetters = localStorage.getItem('sortListByLetters') === 'true';
    localStorage.setItem('sortListByLetters', (sortListByLetters).toString());
    if (sortListByLetters) {
      this.sortButtonText = 'ממוין לפי א - ב';
      this.writersToDisplay = sortByLetters(this.writersList);
    } else {
      this.sortButtonText = 'ממוין לפי תאריך'
      this.writersToDisplay = sortByDate(this.writersList);
    }
  }

  changeSortMethod() {
    const sortListByLetters = localStorage.getItem('sortListByLetters') === 'true';
    localStorage.setItem('sortListByLetters', (!sortListByLetters).toString());
    this.sortList();
  }

  ngOnDestroy() {
    if (this.writersList$Subscription) {
      this.writersList$Subscription.unsubscribe();
    }
  }

  goToReportPage() {
    this.reportsService.reportList.next(this.writersToDisplay);
    this.router.navigate(['/report'])
  }
}