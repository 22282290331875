<div *ngIf="this.locationWithoutParameters === locationPath.BOOK_LIST_SCREEN" class="button-wrapper">
  <div (click)="showOnlySoldedBooks = true">
    <button [ngClass]="{'checked': showOnlySoldedBooks}" (click)="getBooksBySoldCondition(true)">ספרים שנמכרו</button>
  </div>
  <div (click)="showOnlySoldedBooks = false">
    <button [ngClass]="{'checked': !showOnlySoldedBooks}" (click)="getBooksBySoldCondition(false)">ספרים למכירה</button>
  </div>
</div>
<div class="name-search grid">
  <input appCurserSideByLanguaga (keyup)="onKeyUpSearchByName($event)" class="name-input" type="text" placeholder="חפש לפי שם ספר">
</div>
<div class="count-section grid">
  <div *ngIf="booksToDisplay" class="count">ספרים ברשימה {{ booksToDisplay.length }}</div>
  <div (click)="goToReportPage()" class="display-all-btn button"><i class="icofont-list"></i></div>
</div>
<button *ngIf="sortButtonText" (click)="changeSortMethod()" class="sort-by-button">{{ sortButtonText }}</button>
<app-book-list-item *ngFor="let book of booksToDisplay" [book]="book"></app-book-list-item>