<app-note-dialog (closeDialogAndDeleteChanges)="closeDialogAndDeleteChanges($event)" *ngIf="dialogFormGroup !== null" (closeDialog)="closeDialog()" [formGroup]="dialogFormGroup">
</app-note-dialog>
<div class="edit-writer-wrapper">
  <div class="date-stamp-wrapper">
    <div class="date-stamp"> Creation Date: {{ngForm.controls.creationDate.value | date: 'medium'}} </div>
    <div class="date-stamp"> Edit Date: {{ngForm.controls.editDate.value | date:'medium'}}</div>
    <div class="date-stamp"> edited by: {{ngForm.controls.editorUserName.value}}</div>
  </div>
  <div class="top-buttons">
    <div class="fill-location" (click)="updateEditDate()"> תאריך שינוי </div>
    <div class="fill-location" (click)="fillLocation()"> מיקום אוטומטי </div>
  </div>
  <form [formGroup]="ngForm">
    <div class="page-grid">
      <div class="profile-image-section">
        <input (change)="onAddProfileImage($event)" id="profileImage" style="display:none;" type="file" accept="image/*" value="הוסף תמונת פרופיל" class="circle-image">
        <label for="profileImage" class="bold-text inner-grid">
                    <div>
                        <i *ngIf="!ngForm.controls.profileImage.value" class="icofont-user-alt-7"></i>
                        <img *ngIf="ngForm.controls.profileImage.value" [src]="ngForm.controls.profileImage.value"
                            class="profile-image">
                    </div>
                    <div>
                        הוסף תמונת פרופיל
                    </div>
                </label>
      </div>
      <div class="personal-detail-section inner-grid">
        <div class="section-title-w-note inner-grid-2">
          <div>פרטים נוספים</div>
          <div>
            <p (click)="openDialog(ngForm)" class="note"><i class="icofont-pencil-alt-2"></i></p>
          </div>
        </div>

        <input appCurserSideByLanguaga placeholder="שם פרטי" formControlName="firstName">
        <input appCurserSideByLanguaga placeholder="שם משפחה" formControlName="lastName">
        <input type="tel" placeholder="טלפון" formControlName="telephone">
        <input type="tel" placeholder="טלפון נוסף" formControlName="secondTelephone">
        <input appCurserSideByLanguaga type="email" placeholder="אימייל" formControlName="email">
        <input appCurserSideByLanguaga list="cities" placeholder="עיר" formControlName="city">
        <datalist id="cities">
                    <option *ngFor="let city of citiesList" [value]="city">
                </datalist>
        <input appCurserSideByLanguaga placeholder="רחוב" formControlName="street">
        <input appCurserSideByLanguaga placeholder="מספר בנין" formControlName="streetNumber">
        <input appCurserSideByLanguaga placeholder="מספר בית" formControlName="apartmentNumber">
      </div>
    </div>
    <div class="button-wrapper inner-grid">
      <button style="grid-area: eventArena" *ngIf="this.locationWithoutParameters === locationPath.EDIT_DEALER" (click)="onRemove()" class="add-sofer-button">מחק סוחר</button>
      <div>
        <button (click)="onSubmit($event)" class="add-sofer-button">{{ textForSaveButton }}</button>
      </div>
      <div>
        <button class="add-sofer-button">ביטול</button>
      </div>
    </div>
  </form>
</div>