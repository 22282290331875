<div *ngIf="!((this.location.path() === '') || (this.location.path() === locationPath.REMOVE_ITEM) || (this.location.path() === '/login-page'))">
  <div class="nav">
    <div routerLink="/">
      <i class="icofont-home"></i>
    </div>
    <div (click)="syncAllDBS($event)">{{ navBarTitle }}</div>
    <div (click)="goBack()">
      <i class="icofont-arrow-left"></i>
    </div>
  </div>
  <div class="height"></div>
</div>