import { Messages } from '@openreplay/tracker';
import { Encoder, sha1 } from './syncod/index.js';
export default function (opts = {}) {
    const options = Object.assign({
        actionFilter: () => true,
        actionTransformer: action => action,
        actionType: action => action.type,
        stateTransformer: state => state,
    }, opts);
    return (app) => {
        if (app === null) {
            return reducer => (state, action) => reducer(state, action);
        }
        const encoder = new Encoder(sha1, 50);
        return reducer => (state, action) => {
            if (!options.actionFilter(action)) {
                return reducer(state, action);
            }
            const startTime = performance.now();
            state = reducer(state, action);
            const duration = performance.now() - startTime;
            try {
                const type = options.actionType(action);
                if (typeof type === 'string' && type) {
                    app.send(Messages.StateAction(type));
                }
                const _action = encoder.encode(options.actionTransformer(action));
                const _state = encoder.encode(options.stateTransformer(state));
                const _table = encoder.commit();
                for (let key in _table)
                    app.send(Messages.OTable(key, _table[key]));
                app.send(Messages.NgRx(_action, _state, duration));
            }
            catch (_a) {
                encoder.clear();
            }
            return state;
        };
    };
}
