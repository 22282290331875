import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber } from 'libphonenumber-js';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  /**
   * Transforms a phone number into a formatted national representation.
   * @param phoneValue - The phone number to be transformed.
   * @returns The formatted national representation of the phone number.
   */
  transform(phoneValue: string | number): string {
    const stringPhone = String(phoneValue);
    const isIsraeliNumber = stringPhone.startsWith('0');
    const countryCode = isIsraeliNumber ? 'IL' : 'US';
    const phoneNumber = parsePhoneNumber(stringPhone, countryCode);
    const formatted = phoneNumber.formatNational();
    return formatted;
  }

}
