import { Injectable } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Mp3MediaRecorder } from 'mp3-mediarecorder';


const Mp3RecorderWorker = new Worker(new URL('./mp3-recorder-worker.ts', import.meta.url), { type: 'module' });

@Injectable({ providedIn: 'root' })
export class RecordingService {
  lastRecording: SafeResourceUrl;
  mediaRecorder: Mp3MediaRecorder;
  audioChunks: Blob[] = [];

  constructor() { }

  async startRecording() {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: {
        sampleRate: 44100,
        channelCount: 2,
        echoCancellation: true,
        noiseSuppression: true,
        autoGainControl: true,
      }
    });
    this.mediaRecorder = new Mp3MediaRecorder(stream, {
      worker: Mp3RecorderWorker,
      bitsPerSecond: 128000
    });
    this.mediaRecorder.addEventListener('dataavailable', event => {
      this.audioChunks.push((event as any).data as Blob);
    });
    this.mediaRecorder.start();
  }

  stopRecording(): Promise<Blob> {
    return new Promise(resolve => {
      this.mediaRecorder.addEventListener('stop', () => {
        const audioBlob = new Blob(this.audioChunks, { type: 'audio/mpeg' });
        this.audioChunks = [];
        resolve(audioBlob);
      });
      this.mediaRecorder.stop();
    });
  }

  convertRecordingToBase64(audioBlob: Blob): Promise<string> {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(audioBlob);
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
    });
  }

  convertBase64ToAudio(base64: string): Promise<HTMLAudioElement> {
    return new Promise(resolve => {
      const audio = new Audio(base64);
      resolve(audio);
    });
  }
}