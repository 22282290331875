import { Injectable } from '@angular/core';
import OpenReplay from '@openreplay/tracker';

@Injectable({
  providedIn: 'root'
})
export class OpenReplayService {

  constructor() { }

  tracer: OpenReplay;

  sendEvent(eventName: string, data: any) {
    this.tracer.event(eventName, data);
  }
}
