import { Injectable } from '@angular/core';
import { DeepReadonlyObject, RxCollection } from 'rxdb';
import { Writer } from './interfaces';
import { FileUploadService } from './file-upload.service';
import { v4 as uuidv4 } from 'uuid';
import { UtilityService } from './utility.service';
import { DBService } from './stitch-service.service';

@Injectable({
  providedIn: 'root'
})
export class WriterService {
  private writersCollection: RxCollection<Writer>;

  constructor(
    private readonly dbService: DBService,
    private readonly fileUploadService: FileUploadService,
    private readonly utilityService: UtilityService,
  ) {
    this.initCollections();
  }

  private async initCollections() {
    await this.dbService.dbReady;
    this.writersCollection = this.dbService.localWritersDB;
  }

  async createWriter(writer: Writer) {
    await this.dbService.dbReady;
    try {
      await this.utilityService.createCity(writer.city);
      await this.utilityService.createCommunity(writer.communityDeatails.community);

      const writerClone = JSON.parse(JSON.stringify(writer)) as Writer;
      const fullName = `${writerClone.firstName}_${writerClone.lastName}`;

      if (writerClone._id) {
        writerClone.photos = await this.fileUploadService.uploadBase64ImagesAndGetUrls(writerClone.photos, fullName, writerClone._id, 'jpg');
        writerClone.photos_620x620 = writerClone.photos.map(photoUrl => photoUrl.replace('.jpg', '_620x620.jpg'));

        writerClone.profileImage = (await this.fileUploadService.uploadBase64ImagesAndGetUrls([writerClone.profileImage], fullName, writerClone._id, 'jpg'))[0];

        writerClone.recordings = await this.fileUploadService.uploadBase64ImagesAndGetUrls(writerClone.recordings, fullName, writerClone._id, 'mp3');
        await this.writersCollection.upsert(writerClone);
      } else {
        writerClone._id = uuidv4();
        writerClone.photos = await this.fileUploadService.uploadBase64ImagesAndGetUrls(writerClone.photos, fullName, writerClone._id, 'jpg');
        writerClone.photos_620x620 = writerClone.photos.map(photoUrl => photoUrl.replace('.jpg', '_620x620.jpg'));
        writerClone.recordings = await this.fileUploadService.uploadBase64ImagesAndGetUrls(writerClone.recordings, fullName, writerClone._id, 'mp3');
        await this.writersCollection.insert({ ...writerClone, levelOfUrgency: 1 });
      }
    } catch (error) {
      console.error('Error creating writer:', error);
    }
  }

  async getWriters(): Promise<DeepReadonlyObject<Writer>[]> {
    await this.dbService.dbReady;
    try {
      const writerDocs = await this.writersCollection.find().exec();
      return writerDocs.map(doc => doc.toJSON());
    } catch (error) {
      console.error('Error getting writers:', error);
      return [];
    }
  }

  async getWriter(id: string): Promise<DeepReadonlyObject<Writer> | null> {
    await this.dbService.dbReady;
    try {
      const result = await this.writersCollection.findOne(id).exec();
      return result ? result.toJSON() : null;
    } catch (error) {
      console.error('Error getting writer:', error);
      return null;
    }
  }

  async updateWriter(writer: Writer): Promise<void> {
    await this.dbService.dbReady;
    try {
      const writerToUpdate = await this.prepareWriterForCreation(writer);
      await this.writersCollection.upsert(writerToUpdate);
    } catch (error) {
      console.error('Error updating writer:', error);
    }
  }

  private async prepareWriterForCreation(writer: Writer): Promise<Writer> {
    const writerClone = { ...writer };
    await this.utilityService.createCity(writerClone.city);
    await this.utilityService.createCommunity(writerClone.communityDeatails.community);

    const fullName = `${writerClone.firstName}_${writerClone.lastName}`;
    writerClone._id = writerClone._id || uuidv4();

    writerClone.photos = await this.fileUploadService.uploadBase64ImagesAndGetUrls(writerClone.photos, fullName, writerClone._id, 'jpg');
    writerClone.photos_620x620 = writerClone.photos.map(photoUrl =>
      photoUrl.replace('.jpg', '_620x620.jpg')
    );

    writerClone.recordings = await this.fileUploadService.uploadBase64ImagesAndGetUrls(writerClone.recordings, fullName, writerClone._id, 'mp3');

    return writerClone;
  }

  async getWritersFullName() {
    await this.dbService.dbReady;
    try {
      const writersList = await this.writersCollection.find().exec();
      return writersList.map(writer => `${writer.lastName} ${writer.firstName}`);
    } catch (error) {
      console.error('Error getting writers full name:', error);
      return [];
    }
  }

  async getWritersInRoom(city: string, street: string, streetNumber: string): Promise<Writer[]> {
    await this.dbService.dbReady;
    try {
      const result = await this.writersCollection.find({ selector: { city, street, streetNumber } }).exec();
      return result.map(doc => doc.toJSON()) as Writer[];
    } catch (error) {
      console.error('Error getting writers in room:', error);
      return [];
    }
  }
}