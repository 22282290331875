<div class="home-page">
  <div class="homepage-head">
    <div class="title-wrapper">
      <img src="assets/icons/LOGO.png" alt="">
    </div>
  </div>
  <div class="main-wrapper">
    <app-expandable-list class="expandable-list" listTitle="סופרים" [listItems]="[{listItemLink: '/create-writer', listItemText: 'הוספת סופר חדש', image: 'assets/icons/add_sofer.png'},{listItemLink: '/writers-advanced-search', listItemText: 'חיפוש סופר לכתיבה', image: 'assets/icons/search.png'},{listItemLink: '/writers-list-screen', listItemText: 'מאגר סופרים', image: 'assets/icons/maagar.png'}]">
    </app-expandable-list>
    <app-expandable-list class="expandable-list" listTitle="ספרים" [listItems]="[
        {listItemLink: '/create-book', listItemText: 'הוספת ספר חדש', image: 'assets/icons/add_sofer.png'},
        {listItemLink: '/books-advanced-search', listItemText: 'חיפוש ספר לרכישה', image: 'assets/icons/search.png'},
        {listItemLink: '/book-list-screen', listItemText: 'מאגר ספרים', image: 'assets/icons/maagar.png'}
      ]">
    </app-expandable-list>
    <app-expandable-list class="expandable-list" listTitle="סוחרים" [listItems]="[
        {listItemLink: '/create-dealer', listItemText: 'הוספת סוחר חדש', image: 'assets/icons/add_sofer.png'},
        {listItemLink: '/dealer-list-screen', listItemText: 'מאגר סוחרים', image: 'assets/icons/maagar.png'}
      ]">
    </app-expandable-list>
    <app-expandable-list class="expandable-list" listTitle="תזכורות" [listItems]="[
    {listItemLink: '/writer-reminders', listItemText: 'תזכורות סופרים', image: 'assets/icons/maagar.png'},
    {listItemLink: '/book-reminders', listItemText: 'תזכורות ספרים', image: 'assets/icons/maagar.png'}
  ]">
    </app-expandable-list>
  </div>
</div>
<div class="backup-button-wrapper">
  <button (click)="backupApp()">Backup</button>
</div>
<div class="backup-button-wrapper">
  <button (click)="logout()">Logout</button>
</div>
