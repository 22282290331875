import { Injectable } from '@angular/core';
import PouchDB from 'pouchdb';
import WorkerPouch from 'worker-pouch';
(<any>PouchDB).adapter('worker', WorkerPouch);
import { LocalDbNames } from './enums';
import { v4 as uuidv4 } from 'uuid';
import { base64ToBlob, blobToBase64, blobToObjectUrl } from './utils/utils';
import { OpenReplayService } from './openReplay.service';

interface ImgDocument {
  _id: string;
  img: Blob;
  folderName: string;
  fileName: string;
  extension: string;
  imgUrl: string;
  uploded: boolean;
  uplodedTime: number;
  status: 'pending' | 'uploading' | 'completed' | 'failed';
  progress: number;
  _deleted?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  imagesToUploadDB = new PouchDB<ImgDocument>(LocalDbNames.IMAGES_TO_UPLOAD_DB);
  imagesToUploadLeft = 0;

  setImagesLeft = async (): Promise<void> => {
    const info = await this.imagesToUploadDB.info();
    this.imagesToUploadLeft = info.doc_count;
  }

  uploadTimeout: NodeJS.Timeout;
  constructor(private openReplayService: OpenReplayService) {
    this.setImagesLeft();
    /** @type {number} - The ID of the current upload timeout. */
    this.uploadTimeout = null;
    this.resetUploadTimer();
  }

  async addImagesToQueueOfUploads(img: Blob, folderName: string, extension: string) {
    const fileName = uuidv4();
    const imgUrl = this.getUrlOfImage(folderName, fileName, extension);
    this.imagesToUploadDB.put({
      _id: uuidv4(),
      img,
      folderName,
      fileName,
      extension,
      imgUrl,
      uploded: false,
      uplodedTime: null,
      status: 'pending',
      progress: 0
    });
    this.setImagesLeft();
    this.triggerUpload();
    return imgUrl;
  }

  async removeImagesFromQueueOfUploads(id: string) {
    const documentToRemove = await this.imagesToUploadDB.get(id);
    documentToRemove._deleted = true;
    documentToRemove.img = new Blob();
    this.imagesToUploadDB.put(documentToRemove);
    this.setImagesLeft();
  }

  async uploadImagesToFirebase() {
    const documents = await this.imagesToUploadDB.allDocs<ImgDocument>({ include_docs: true });
    console.log('documents', documents.rows);
    for (const document of documents.rows) {
      if (document.doc?.uploded === false && (document.doc?.status !== 'uploading' || document.doc?.uplodedTime < new Date().getTime() - 3 * 60 * 1000)) {
        await this.uploadImage(document.doc);
      }
    }
  }

  async uploadImage(document: ImgDocument) {
    const img = await blobToBase64(document.img);
    const form = {
      folderName: document.folderName,
      filename: `${document.fileName}.${document.extension}`,
      img,
    };
    const uploadDetailes = { fileName: form.filename, folderName: form.folderName, startTime: new Date().getTime(), endTime: null };

    document.status = 'uploading';
    await this.imagesToUploadDB.put(document);
    document = await this.imagesToUploadDB.get(document._id);
    try {
      const response = await fetch('https://us-central1-ashuris.cloudfunctions.net/aabbcc', {
        method: 'POST',
        body: JSON.stringify(form),
      });

      if (response.status !== 200) {
        throw new Error('Error uploading image');
      }

      uploadDetailes.endTime = new Date().getTime();
      this.openReplayService.sendEvent('imageUpload', uploadDetailes);

      document.uploded = true;
      document.uplodedTime = new Date().getTime();
      document.status = 'completed';
      document.progress = 100;
      await this.imagesToUploadDB.put(document);
      document = await this.imagesToUploadDB.get(document._id);
      await this.removeImagesFromQueueOfUploads(document._id);
    } catch (error) {
      console.error('Error uploading image', error);
      document.status = 'failed';
      document = await this.imagesToUploadDB.get(document._id);
      await this.imagesToUploadDB.put(document);
    }
  }

  getUrlOfImage(folderName: string, fileName: string, extension: string) {
    return `https://firebasestorage.googleapis.com/v0/b/ashuris.appspot.com/o/${folderName}%2F${fileName}.${extension}?alt=media`;
  }

  async getImageFromQueue(idUrl: string) {
    const allDocs = (await this.imagesToUploadDB.allDocs<ImgDocument>({ include_docs: true })).rows.map(doc => doc.doc);
    const img = allDocs.find(doc => doc.imgUrl === idUrl);
    return img;
  }

  async getFromAvailableResources(urls: string[]): Promise<string[]> {
    return urls = await Promise.all(urls.map(async (url) => {
      try {
        const base64 = blobToObjectUrl(await base64ToBlob(url));
        return base64;
      } catch (error) {
        try {
          if (url.includes('jpg')) {
            url = url.replace('_620x620', '');
          }
          const img = await this.getImageFromQueue(url);
          if (img) {
            return blobToObjectUrl(img.img);
          }
          console.log('Can\'t find img');
          return '';
        } catch (err) {
          console.log(err);
          return '';
        }
      }
    }));
  }

  /**
   * Upload data and reset the timer for the next upload.
   */
  uploadData() {
    this.uploadImagesToFirebase();
    this.resetUploadTimer();
  }

  /**
   * Clear the existing timer and set a new one for the next upload.
   */
  resetUploadTimer() {
    clearTimeout(this.uploadTimeout);
    this.uploadTimeout = setTimeout(() => this.uploadData(), 60_000);
  }

  /**
   * Manually trigger the upload and reset the timer.
   */
  triggerUpload() {
    this.uploadData();
  }
}
