<app-note-dialog (closeDialogAndDeleteChanges)="closeDialogAndDeleteChanges($event)" *ngIf="dialogFormGroup !== null" (closeDialog)="closeDialog()" [formGroup]="dialogFormGroup">
</app-note-dialog>
<div class="edit-book-wrapper">
  <div class="date-stamp-wrapper">
    <div class="date-stamp"> Creation Date: {{ngForm.controls.creationDate.value | date: 'medium'}} </div>
    <div class="date-stamp"> Edit Date: {{ngForm.controls.editDate.value | date:'medium'}}</div>
    <div class="date-stamp"> edited by: {{ngForm.controls.editorUserName.value}}</div>
  </div>
  <div class="fill-location" (click)="updateEditDate()"> תאריך שינוי </div>
  <form [formGroup]="ngForm" class="page-grid">
    <div class="personal-detail-section inner-grid">
      <div class="section-title-w-note inner-grid-2">
        <div>פרטים כלליים</div>
        <div>
          <p (click)="openDialog(ngForm)" class="note"><i class="icofont-pencil-alt-2"></i></p>
        </div>
      </div>
      <input appCurserSideByLanguaga placeholder="שם" formControlName="name">
      <datalist id="writers">
        <option *ngFor="let writer of writersFullNameList" [value]="writer">
      </datalist>
      <input appCurserSideByLanguaga (focusout)="setDealerId($event)" list="dealers" placeholder="סוחר" formControlName="dealer" class="full-width">
      <datalist id="dealers">
        <option *ngFor="let dealer of dealerList" [value]="dealer.fullName" [id]="dealer._id">
      </datalist>
      <div class="margin-top-bottom" (click)="routeToCreateNewDealer()"> + הוספת סוחר חדש למאגר</div>

      <div formGroupName="isSold" class="inner-grid-2 is-sold-section">
        <div class="align-center">
          <input id="is-sold" class="visible-input" type="checkbox" formControlName="boolean">
        </div>
        <div>
          <label class="section-title" for="is-sold">האם נמכר</label>
        </div>
      </div>

    </div>
    <div class="end-date-section">
      <app-hebrew-date-form-control [parentForm]="ngForm" formGroupName="endDate" sectionTitle="תאריך סיום">
      </app-hebrew-date-form-control>
    </div>
    <div formGroupName="isAppropriate" class="is-appropriate-section">
      <div class="is-appropriate-radio-buttons-wraper inner-grid">
        <div class="section-title">
          מידת התאמה
        </div>
        <div>
          <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['isAppropriate', 'level']).value === 'לא מתאים'}">
            <input type="radio" formControlName="level" value="לא מתאים">
            1
          </label>
        </div>
        <div>
          <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['isAppropriate', 'level']).value === 'מתאים'}">
            <input type="radio" formControlName="level" value="מתאים">
            2
          </label>
        </div>
        <div>
          <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['isAppropriate', 'level']).value === 'כדאי מאוד'}">
            <input type="radio" formControlName="level" value="כדאי מאוד">
            3
          </label>
        </div>
      </div>
    </div>
    <div formGroupName="writingDeatails" class="writing-detail-section inner-grid">
      <div formGroupName="writingLevel" class="writing-level inner-grid-2">
        <div>
          <div class="section-title-w-note inner-grid-2">
            <div>דרגת כתיבה</div>
            <p (click)="openDialog(HelperAbstractControlToFormGroup(ngForm.get(['writingDeatails', 'writingLevel'])))" class="note"><i class="icofont-pencil-alt-2"></i></p>
          </div>
        </div>
        <div class="number-button-wrapper inner-grid-2">

          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'writingLevel', 'level']).value === 'זול'}">
              <input type="radio" formControlName="level" value="זול">
              1
            </label>
          </div>
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'writingLevel', 'level']).value === 'פחותה'}">
              <input type="radio" formControlName="level" value="פחותה">
              2
            </label>
          </div>
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'writingLevel', 'level']).value === 'בינוני'}">
              <input type="radio" formControlName="level" value="בינוני">
              3
            </label>
          </div>
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'writingLevel', 'level']).value === 'גבוהה'}">
              <input type="radio" formControlName="level" value="גבוהה">
              4
            </label>
          </div>
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'writingLevel', 'level']).value === 'מיוחד'}">
              <input type="radio" formControlName="level" value="מיוחד">
              5
            </label>
          </div>
        </div>
      </div>
      <div formGroupName="stabilityLevel" class="stability-level inner-grid-2">
        <div class="section-title-w-note inner-grid-2">
          <div>
            יציבות
          </div>
          <p (click)="openDialog(HelperAbstractControlToFormGroup(ngForm.get(['writingDeatails', 'stabilityLevel'])))" ass="note"><i class="icofont-pencil-alt-2"></i></p>
        </div>
        <div class="number-button-wrapper inner-grid-2">
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'stabilityLevel', 'level']).value === '1'}">
              <input type="radio" formControlName="level" value="1">
              1
            </label>
          </div>
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'stabilityLevel', 'level']).value === '2'}">
              <input type="radio" formControlName="level" value="2">
              2
            </label>
          </div>
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'stabilityLevel', 'level']).value === '3'}">
              <input type="radio" formControlName="level" value="3">
              3
            </label>
          </div>
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'stabilityLevel', 'level']).value === '4'}">
              <input type="radio" formControlName="level" value="4">
              4
            </label>
          </div>
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'stabilityLevel', 'level']).value === '5'}">
              <input type="radio" formControlName="level" value="5">
              5
            </label>
          </div>
        </div>
      </div>
      <div formGroupName="eraseLevel" class="erase-level inner-grid-2">

        <div class="section-title-w-note inner-grid-2">
          <div>
            מחיקה
          </div>
          <p (click)="openDialog(HelperAbstractControlToFormGroup(ngForm.get(['writingDeatails', 'eraseLevel'])))" ass="note"><i class="icofont-pencil-alt-2"></i></p>
        </div>
        <div class="number-button-wrapper inner-grid-2">
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'eraseLevel', 'level']).value === '1'}">
              <input type="radio" formControlName="level" value="1">
              1
            </label>
          </div>
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'eraseLevel', 'level']).value === '2'}">
              <input type="radio" formControlName="level" value="2">
              2
            </label>
          </div>
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'eraseLevel', 'level']).value === '3'}">
              <input type="radio" formControlName="level" value="3">
              3
            </label>
          </div>
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'eraseLevel', 'level']).value === '4'}">
              <input type="radio" formControlName="level" value="4">
              4
            </label>
          </div>
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'eraseLevel', 'level']).value === '5'}">
              <input type="radio" formControlName="level" value="5">
              5
            </label>
          </div>
        </div>
      </div>
      <div formGroupName="parchmentLevel" class="erase-level inner-grid-2">
        <div class="section-title-w-note inner-grid-2">
          <div>
            איכות הקלף
          </div>
          <p (click)="openDialog(HelperAbstractControlToFormGroup(ngForm.get(['writingDeatails', 'parchmentLevel'])))" ass="note"><i class="icofont-pencil-alt-2"></i></p>
        </div>
        <div class="number-button-wrapper inner-grid-2">
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'parchmentLevel', 'level']).value === '1'}">
              <input type="radio" formControlName="level" value="1">
              1
            </label>
          </div>
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'parchmentLevel', 'level']).value === '2'}">
              <input type="radio" formControlName="level" value="2">
              2
            </label>
          </div>
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'parchmentLevel', 'level']).value === '3'}">
              <input type="radio" formControlName="level" value="3">
              3
            </label>
          </div>
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'parchmentLevel', 'level']).value === '4'}">
              <input type="radio" formControlName="level" value="4">
              4
            </label>
          </div>
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'parchmentLevel', 'level']).value === '5'}">
              <input type="radio" formControlName="level" value="5">
              5
            </label>
          </div>
        </div>
      </div>
      <div formGroupName="parchmentType" class="parchment-section">
        <div class="section-title-w-note inner-grid-2">
          <div>
            סוג הקלף
          </div>
          <p (click)="openDialog(HelperAbstractControlToFormGroup(ngForm.get(['writingDeatails', 'parchmentType'])))" class="note">
            <i class="icofont-pencil-alt-2"></i>
          </p>
        </div>
        <input appCurserSideByLanguaga list="parchmentTypes" placeholder="בחר סוג קלף" formControlName="type" class="full-width">
        <datalist id="parchmentTypes">
          <option *ngFor="let parchmentType of parchmentTypes" [value]="parchmentType">
        </datalist>
      </div>
    </div>
    <div formGroupName="writingDeatails" class="writing-type-section inner-grid">
      <div>
        <div class="writing-types inner-grid-2">
          <div class="section-title">
            סוג הכתב
          </div>
          <div>
            <label [ngClass]="{'checked': ngForm.get(['writingDeatails', 'writingType']).value === writingTypes.ari}" class="box-checkbox">
              אר"י
              <input type="radio" [value]="writingTypes.ari" formControlName="writingType">
            </label>
          </div>
          <div>
            <label [ngClass]="{'checked': ngForm.get(['writingDeatails', 'writingType']).value === writingTypes.beitYosef}" class="box-checkbox">
              בית יוסף
              <input type="radio" [value]="writingTypes.beitYosef" formControlName="writingType">
            </label>
          </div>
          <div>
            <label [ngClass]="{'checked': ngForm.get(['writingDeatails', 'writingType']).value === writingTypes.welish}" class="box-checkbox">
              וועליש
              <input type="radio" [value]="writingTypes.welish" formControlName="writingType">
            </label>
          </div>
        </div>
      </div>
    </div>
    <div formGroupName="writingDeatails" class="sizes-section inner-grid">
      <div formGroupName="letterSize" class="letter-sizes-wrapper">
        <div class="inner-grid-2">
          <div class="section-title-w-note inner-grid-2">
            <div>
              גודל הכתב
            </div>
            <p (click)="openDialog(HelperAbstractControlToFormGroup(ngForm.get(['writingDeatails', 'letterSize'])))" class="note"><i class="icofont-pencil-alt-2"></i></p>
          </div>
          <div class="sizes-row inner-grid-2">

            <label [ngClass]="{'checked': ngForm.get(['writingDeatails', 'letterSize', 'size']).value === '17'}" class="size-checkbox">
              <input type="radio" formControlName="size" value="17"> 17
            </label>
            <label [ngClass]="{'checked': ngForm.get(['writingDeatails', 'letterSize', 'size']).value === '24'}" class="size-checkbox">
              <input type="radio" formControlName="size" value="24"> 24
            </label>
            <label [ngClass]="{'checked': ngForm.get(['writingDeatails', 'letterSize', 'size']).value === '30'}" class="size-checkbox">
              <input type="radio" formControlName="size" value="30"> 30
            </label>
            <label [ngClass]="{'checked': ngForm.get(['writingDeatails', 'letterSize', 'size']).value === '36'}" class="size-checkbox">
              <input type="radio" formControlName="size" value="36"> 36
            </label>
          </div>
          <div class="sizes-row inner-grid-2">
            <label [ngClass]="{'checked': ngForm.get(['writingDeatails', 'letterSize', 'size']).value === '40'}" class="size-checkbox">
              <input type="radio" formControlName="size" value="40"> 40
            </label>
            <label [ngClass]="{'checked': ngForm.get(['writingDeatails', 'letterSize', 'size']).value === '42'}" class="size-checkbox">
              <input type="radio" formControlName="size" value="42"> 42
            </label>
            <label [ngClass]="{'checked': ngForm.get(['writingDeatails', 'letterSize', 'size']).value === '45'}" class="size-checkbox">
              <input type="radio" formControlName="size" value="45"> 45
            </label>
            <label [ngClass]="{'checked': ngForm.get(['writingDeatails', 'letterSize', 'size']).value === '48'}" class="size-checkbox">
              <input type="radio" formControlName="size" value="48"> 48
            </label>
          </div>
        </div>
      </div>
    </div>
    <div formGroupName="communityDeatails" class="community-section inner-grid">
      <div class="section-title-w-note inner-grid-2">
        <div>
          קהילה
        </div>
        <p (click)="openDialog(HelperAbstractControlToFormGroup(ngForm.get(['communityDeatails'])))" class="note">
          <i class="icofont-pencil-alt-2"></i>
        </p>
      </div>
      <input appCurserSideByLanguaga list="communities" placeholder="התחל להקליד קהילה" formControlName="community" class="full-width">
      <datalist id="communities">
        <option *ngFor="let community of communitiesList" [value]="community">
      </datalist>
    </div>
    <div formGroupName="additionalDetails">
      <div>
        <div formGroupName="writerLevel" class="writer-level-section inner-grid">
          <div class="section-title span-title">
            <div>
              דרגת סופר
            </div>
          </div>
          <p (click)="openDialog(HelperAbstractControlToFormGroup(ngForm.get(['additionalDetails', 'writerLevel'])))" class="note">
            <i class="icofont-pencil-alt-2"></i>
          </p>
          <div>
            <label [ngClass]="{'checked': ngForm.get(['additionalDetails', 'writerLevel', 'level']).value === '1'}" class="level-checkbox">
              <input type="radio" formControlName="level" value="1">
              1
            </label>
          </div>
          <div>
            <label [ngClass]="{'checked': ngForm.get(['additionalDetails', 'writerLevel', 'level']).value === '2'}" class="level-checkbox">
              <input type="radio" formControlName="level" value="2">
              2
            </label>
          </div>
          <div>
            <label [ngClass]="{'checked': ngForm.get(['additionalDetails', 'writerLevel', 'level']).value === '3'}" class="level-checkbox">
              <input type="radio" formControlName="level" value="3">
              3
            </label>
          </div>
          <div>
            <label [ngClass]="{'checked': ngForm.get(['additionalDetails', 'writerLevel', 'level']).value === '4'}" class="level-checkbox">
              <input type="radio" formControlName="level" value="4">
              4
            </label>
          </div>
          <div>
            <label [ngClass]="{'checked': ngForm.get(['additionalDetails', 'writerLevel', 'level']).value === '5'}" class="level-checkbox">
              <input type="radio" formControlName="level" value="5">
              5
            </label>
          </div>
        </div>
      </div>
    </div>
    <div formGroupName="pricesDeatails" class="price-deatail-section inner-grid">
      <div class="section-title">
        מחיר
      </div>
      <div class="type-of-price-selection-wrapper inner-grid-2">
        <div>
          <label class="box-checkbox" [ngClass]="{'checked-blue': ngForm.get(['pricesDeatails','isPricePerPage']).value === 'מחיר לעמוד'}">
            <input type="radio" formControlName="isPricePerPage" value='מחיר לעמוד'>
            מחיר לעמוד
          </label>
        </div>
        <div>
          <label class="box-checkbox" [ngClass]="{'checked-blue': ngForm.get(['pricesDeatails','isPricePerPage']).value === 'מחיר לספר תורה'}">
            <input type="radio" formControlName="isPricePerPage" value='מחיר לספר תורה'>
            מחיר לס"ת
          </label>
        </div>
      </div>
      <div class="prices-input-wrapper">
        <div class="input-grid">
          <div class="book-desc">מחיר</div>
          <div formGroupName="priceForTorahScroll" class="gap inner-grid-2">
            <input currencyMask appCurserToEnd type="tel" [placeholder]="ngForm.get(['pricesDeatails','isPricePerPage']).value" formControlName="price">
          </div>
        </div>
        <div class="input-grid">
          <div class="book-desc">שווה</div>
          <div formGroupName="priceForTorahScroll" class="inner-grid-2">
            <input currencyMask appCurserToEnd type="tel" placeholder="כמה שווה לדעתי" formControlName="howMuchIsItWorth">
          </div>
        </div>
      </div>
      <div class=" inner-grid-2">

        <div formGroupName="priceForTorahScroll" class="price-for-item inner-grid-2">
          <div class="book-desc">מו"מ</div>
          <input type="text" placeholder="משא ומתן" formControlName="negotiation">
          <p (click)="openDialog(HelperAbstractControlToFormGroup(ngForm.get(['pricesDeatails', 'priceForTorahScroll'])))" class="note-absolute-position"><i class="icofont-pencil-alt-2"></i></p>
        </div>
      </div>
    </div>
    <div formGroupName="additionalDetails" class="additional-details-section inner-grid">
      <div class="section-title">
        פרטים נוספים
      </div>
      <div>
        <div formGroupName="hasKtavKabala" class="additional-detail inner-grid-2 stroke">
          <div class="description">
            כתב קבלה
          </div>
          <label [ngClass]="{'checked': ngForm.get(['additionalDetails', 'hasKtavKabala', 'boolean']).value === 'false'}" class="size-checkbox">
            <i class="icofont-close-line"></i>
            <input type="radio" formControlName="boolean" value='false'
              (click)="isChecked($event, ngForm.get(['additionalDetails', 'hasKtavKabala', 'boolean']))">
          </label>
          <label [ngClass]="{'checked': ngForm.get(['additionalDetails', 'hasKtavKabala', 'boolean']).value === 'true'}" class="size-checkbox">
            <i class="icofont-check-alt"></i>
            <input type="radio" formControlName="boolean" value='true'
              (click)="isChecked($event, ngForm.get(['additionalDetails', 'hasKtavKabala', 'boolean']))">
          </label>
          <p (click)="openDialog(HelperAbstractControlToFormGroup(ngForm.get(['additionalDetails', 'hasKtavKabala'])))" class="note"><i class="icofont-pencil-alt-2"></i></p>
        </div>
        <div formGroupName="voatsInElection" class="additional-detail inner-grid-2 stroke">
          <div class="description">
            בחירות
          </div>
          <label [ngClass]="{'checked': ngForm.get(['additionalDetails', 'voatsInElection', 'boolean']).value === 'false'}" class="size-checkbox">
            <i class="icofont-close-line"></i>
            <input type="radio" formControlName="boolean" value='false'
              (click)="isChecked($event, ngForm.get(['additionalDetails', 'voatsInElection', 'boolean']))">
          </label>
          <label [ngClass]="{'checked': ngForm.get(['additionalDetails', 'voatsInElection', 'boolean']).value === 'true'}" class="size-checkbox">
            <i class="icofont-check-alt"></i>
            <input type="radio" formControlName="boolean" value='true'
              (click)="isChecked($event, ngForm.get(['additionalDetails', 'voatsInElection', 'boolean']))">
          </label>
          <p (click)="openDialog(HelperAbstractControlToFormGroup(ngForm.get(['additionalDetails', 'voatsInElection'])))" class="note"><i class="icofont-pencil-alt-2"></i></p>
        </div>
        <div formGroupName="goesToKotel" class="additional-detail inner-grid-2">
          <div class="description">
            הולך לכותל
          </div>
          <label [ngClass]="{'checked': ngForm.get(['additionalDetails', 'goesToKotel', 'boolean']).value === 'false'}" class="size-checkbox">
            <i class="icofont-close-line"></i>
            <input type="radio" formControlName="boolean" value='false'
              (click)="isChecked($event, ngForm.get(['additionalDetails', 'goesToKotel', 'boolean']))">
          </label>
          <label [ngClass]="{'checked': ngForm.get(['additionalDetails', 'goesToKotel', 'boolean']).value === 'true'}" class="size-checkbox">
            <i class="icofont-check-alt"></i>
            <input type="radio" formControlName="boolean" value='true'
              (click)="isChecked($event, ngForm.get(['additionalDetails', 'goesToKotel', 'boolean']))">
          </label>
          <p (click)="openDialog(HelperAbstractControlToFormGroup(ngForm.get(['additionalDetails', 'goesToKotel'])))" class="note">
            <i class="icofont-pencil-alt-2"></i>
          </p>
        </div>
      </div>
    </div>

    <div formArrayName="photos" class="photo-section inner-grid">
      <label for="addPhoto" class="second-title">
        <div class="inner-grid-2 add-section">
          <img src="assets/icons/Add.png" alt="">
          <div>
            הוסף תמונה
          </div>
          <input (change)="onAddPhoto($event)" id="addPhoto" style="display:none;" type="file"
            accept="image/*">
        </div>
      </label>
      <div *ngFor="let photoControl of ngForm.controls.photos['controls']; let i = index;">
        <div (click)="deletePhoto(i)">מחק תמונה</div>
        <img appDoubleTapOpenImg [base64Img]="ngForm.controls.photos.value[i]" [src]="ngForm.controls.photos_620x620.value[i]" class="img">
      </div>
    </div>
    <div class="inner-grid">
      <label *ngIf="!isRecording" for="click" (click)="startRecording()">
        <div class="inner-grid-2 add-section">
          <div>
              <img src="assets/icons/Add.png" alt="">
          </div>
          <div class="second-title">הוסף תזכורת קולית</div>
        </div>
      </label>
      <div *ngIf="isRecording" (click)="stopRecording()">
        עצור הקלטה
      </div>
      <div>
        <div *ngFor="let recording of ngForm.controls.recordings['controls']; let i = index;">
          <div (click)="deleteRecording(i)">מחק הקלטה</div>
          <app-audio-html5 [recordingUrl]="recording.value"></app-audio-html5>
        </div>
      </div>
    </div>
    <button *ngIf="this.locationWithoutParameters === locationPath.EDIT_BOOK" (click)="onRemove()" class="add-book-button">מחק ספר</button>
    <button (click)="onSubmit($event)" class="add-book-button">{{ textForSaveButton }}</button>
  </form>
</div>
