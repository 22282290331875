<div class="wrapper fadeInDown">
  <div id="formContent">
    <!-- Tabs Titles -->
    <h2 class=""> כניסה </h2>

    <!-- Login Form -->
    <form [formGroup]="loginForm">
      <input type="text" id="login" class="fadeIn second" placeholder="שם משתמש" required formControlName="userName">
      <input type="text" id="password" class="fadeIn third" placeholder="סיסמה" required formControlName="password">
      <input type="button" (click)="onSubmit()" class="fadeIn fourth" value="שלח">
    </form>

  </div>
</div>