import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ExchangeRateService {
  private readonly apiUrl = 'https://openexchangerates.org/api/';
  private readonly appId = '290d3da5ca614a1cb7a67622eea62e9d';

  constructor(private readonly http: HttpClient) { }

  public getExchangeRate(): Observable<number> {
    const url = `${this.apiUrl}latest.json?app_id=${this.appId}&base=USD`;
    return this.http.get<{
      base: "USD", rates: { ILS: number },
    }>(url).pipe(
      map(response => response.rates['ILS']),
      catchError(error => {
        console.error('Error fetching exchange rate:', error);
        return of(null);
      })
    );
  }
}
