import { Pipe, PipeTransform } from '@angular/core';
import { Price } from 'src/app/interfaces';
import { DBService } from 'src/app/stitch-service.service';
@Pipe({
  name: 'currencyConverter'
})
export class CurrencyConverterPipe implements PipeTransform {

  constructor(private exchangeRateService: DBService) {}

  async transform(price: Price, targetCurrency: '$' | '₪', conversionRate?: number): Promise<string> {
    // If the price object's currency is the same as the target currency, no conversion needed
    if (price.currency === targetCurrency) {
      return `${targetCurrency}${price.price.toFixed(0)}`;
    }

    if (!conversionRate) {
      conversionRate = await this.exchangeRateService.getExchangeRates();
    }

    // Convert the price value based on the target currency and conversion rate
    const convertedValue = targetCurrency === '$' ? price.price / conversionRate : price.price * conversionRate;
    return `${targetCurrency === '$' ? '$' : '₪'}${convertedValue.toFixed(0)}`;
  }
}