<app-note-dialog (closeDialogAndDeleteChanges)="closeDialogAndDeleteChanges($event)" *ngIf="dialogFormGroup !== null" (closeDialog)="closeDialog()" [formGroup]="dialogFormGroup">
</app-note-dialog>
<div class="edit-writer-wrapper">
  <div class="date-stamp-wrapper">
    <div class="date-stamp"> Creation Date: {{ngForm.controls.creationDate.value | date: 'medium'}} </div>
    <div class="date-stamp"> Edit Date: {{ngForm.controls.editDate.value | date:'medium'}}</div>
    <div class="date-stamp"> edited by: {{ngForm.controls.editorUserName.value}}</div>
  </div>
  <div class="top-buttons">
    <div class="fill-location" (click)="updateEditDate()"> תאריך שינוי </div>
    <div class="fill-location" (click)="fillLocation()"> מיקום אוטומטי </div>
  </div>
  <form [formGroup]="ngForm" class="page-grid">
    <div class="profile-image-section">
      <input (change)="onAddProfileImage($event)" id="profileImage" style="display:none;" type="file" accept="image/*" value="הוסף תמונת פרופיל" class="circle-image">
      <label for="profileImage" class="bold-text inner-grid">
        <div>
          <i *ngIf="!ngForm.controls.profileImage.value" class="icofont-user-alt-7"></i>
          <img *ngIf="ngForm.controls.profileImage.value" [src]="ngForm.controls.profileImage.value"
            class="profile-image">
        </div>
        <div>
          הוסף תמונת פרופיל
        </div>
      </label>
    </div>
    <div class="personal-detail-section inner-grid">
      <div class="section-title-w-note inner-grid-2">
        <div>פרטים אישיים</div>
        <div>
          <p (click)="openDialog(ngForm)" class="note"><i class="icofont-pencil-alt-2"></i></p>
        </div>
      </div>

      <input appCurserSideByLanguaga placeholder="שם פרטי" formControlName="firstName">
      <input appCurserSideByLanguaga placeholder="שם משפחה" formControlName="lastName">
      <input type="tel" placeholder="טלפון" formControlName="telephone">
      <input type="tel" placeholder="טלפון נוסף" formControlName="secondTelephone">
      <input appCurserSideByLanguaga type="email" placeholder="אימייל" formControlName="email">
      <input appCurserSideByLanguaga list="cities" placeholder="עיר" formControlName="city">
      <datalist id="cities">
        <option *ngFor="let city of citiesList" [value]="city">
      </datalist>
      <input appCurserSideByLanguaga placeholder="רחוב" formControlName="street">
      <input appCurserSideByLanguaga placeholder="מספר בנין" formControlName="streetNumber">
      <input appCurserSideByLanguaga placeholder="מספר בית" formControlName="apartmentNumber">
    </div>
    <div class="start-date-section">
      <app-hebrew-date-form-control [parentForm]="ngForm" formGroupName="startDate" sectionTitle="תאריך התחלה">
      </app-hebrew-date-form-control>
    </div>
    <div formGroupName="lengthOfWritingBook" class="community-section inner-grid">
      <div class="section-title-w-note inner-grid-2">
        <div>
          אורך כתיבת ספר
        </div>
        <p (click)="openDialog(HelperAbstractControlToFormGroup(ngForm.get(['lengthOfWritingBook'])))" class="note">
          <i class="icofont-pencil-alt-2"></i>
        </p>
      </div>
      <input appCurserSideByLanguaga placeholder="אורך כתיבת ספר" formControlName="length" class="full-width">
    </div>
    <div formGroupName="isAppropriate" class="is-appropriate-section">
      <div class="is-appropriate-radio-buttons-wraper inner-grid">
        <div class="section-title">
          מידת התאמה
        </div>
        <div>
          <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['isAppropriate', 'level']).value === 'לא מתאים'}">
            <input type="radio" formControlName="level" value="לא מתאים">
            1
          </label>
        </div>
        <div>
          <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['isAppropriate', 'level']).value === 'מתאים'}">
            <input type="radio" formControlName="level" value="מתאים">
            2
          </label>
        </div>
        <div>
          <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['isAppropriate', 'level']).value === 'כדאי מאוד'}">
            <input type="radio" formControlName="level" value="כדאי מאוד">
            3
          </label>
        </div>
      </div>
    </div>
    <div formGroupName="isWritingRegularly" class="inner-grid is-sold-section">
      <div class="align-center">
        <input id="isWritingRegularly" class="visible-input" type="checkbox" formControlName="boolean">
      </div>
      <div>
        <label class="section-title" for="isWritingRegularly">סופר קבוע</label>
      </div>
      <div>
      </div>
    </div>
    <div formGroupName="pricesDeatails" class="price-deatail-section inner-grid">
      <div class="section-title">
        מחירים
      </div>
      <div class="type-of-price-selection-wrapper inner-grid-2">

        <div>
          <label class="box-checkbox" [ngClass]="{'checked-blue': ngForm.get(['pricesDeatails','isPricePerPage']).value === 'מחיר לעמוד'}">
            <input type="radio" formControlName="isPricePerPage" value='מחיר לעמוד'>
            עמוד
          </label>
        </div>
        <div>
          <label class="box-checkbox" [ngClass]="{'checked-blue': ngForm.get(['pricesDeatails','isPricePerPage']).value === 'מחיר לספר תורה'}">
            <input type="radio" formControlName="isPricePerPage" value='מחיר לספר תורה'>
            ס"ת
          </label>
        </div>
      </div>
      <div class="prices-input-wrapper inner-grid-2">
        <div formGroupName="priceForTorahScroll" class="price-for-item inner-grid-2">
          <div class="book-desc">ספר</div>
          <div class="price-currency-container">
              <input currencyMask [options]="{ prefix: ngForm.get(['pricesDeatails', 'priceForTorahScroll', 'currency']).value === '$' ? '$' : '₪'}" appCurserToEnd type="tel" [placeholder]="ngForm.get(['pricesDeatails','isPricePerPage']).value" formControlName="price">
              <button class="currency-button" (click)="toggleCurrency(ngForm.get(['pricesDeatails', 'priceForTorahScroll', 'currency']))">
                <span class="currency-sign">{{ngForm.get(['pricesDeatails', 'priceForTorahScroll', 'currency']).value}}</span>
              </button>
          </div>
          <div class="worth-it-buttons-wrapper inner-grid-2">
            <label [ngClass]="{'checked': ngForm.get(['pricesDeatails', 'priceForTorahScroll', 'worthIt']).value === 'false'}" class="size-checkbox">
              <i class="icofont-close-line"></i>
              <input type="radio" formControlName="worthIt" value='false'
                (click)="isChecked($event, ngForm.get(['pricesDeatails', 'priceForTorahScroll', 'worthIt']))">
            </label>
            <label [ngClass]="{'checked': ngForm.get(['pricesDeatails', 'priceForTorahScroll', 'worthIt']).value === 'true'}" class="size-checkbox">
              <i class="icofont-check-alt"></i>
              <input type="radio" formControlName="worthIt" value='true'
                (click)="isChecked($event, ngForm.get(['pricesDeatails', 'priceForTorahScroll', 'worthIt']))">
            </label>

          </div>
          <p (click)="openDialog(HelperAbstractControlToFormGroup(ngForm.get(['pricesDeatails', 'priceForTorahScroll'])))" class="note"><i class="icofont-pencil-alt-2"></i></p>
        </div>
        <div formGroupName="priceForMezuzah" class="price-for-item inner-grid-2">
          <div class="book-desc">מזוזה</div>
          <div class="price-currency-container">
              <input currencyMask [options]="{ prefix: ngForm.get(['pricesDeatails', 'priceForMezuzah', 'currency']).value === '$' ? '$' : '₪'}" appCurserToEnd type="tel" placeholder="מחיר למזוזה" formControlName="price">
              <button class="currency-button" (click)="toggleCurrency(ngForm.get(['pricesDeatails', 'priceForMezuzah', 'currency']))">
                <span class="currency-sign">{{ngForm.get(['pricesDeatails', 'priceForMezuzah', 'currency']).value}}</span>
              </button>
          </div>
          <div class="worth-it-buttons-wrapper inner-grid-2">
            <label [ngClass]="{'checked': ngForm.get(['pricesDeatails', 'priceForMezuzah', 'worthIt']).value === 'false'}" class="size-checkbox">
              <i class="icofont-close-line"></i>
              <input type="radio" formControlName="worthIt" value='false'
                (click)="isChecked($event, ngForm.get(['pricesDeatails', 'priceForMezuzah', 'worthIt']))">
            </label>
            <label [ngClass]="{'checked': ngForm.get(['pricesDeatails', 'priceForMezuzah', 'worthIt']).value === 'true'}" class="size-checkbox">
              <i class="icofont-check-alt"></i>
              <input type="radio" formControlName="worthIt" value='true'
                (click)="isChecked($event, ngForm.get(['pricesDeatails', 'priceForMezuzah', 'worthIt']))">
            </label>

          </div>
          <p (click)="openDialog(HelperAbstractControlToFormGroup(ngForm.get(['pricesDeatails', 'priceForMezuzah'])))" class="note"><i class="icofont-pencil-alt-2"></i></p>
        </div>
        <div formGroupName="priceForTefillin" class="price-for-item inner-grid-2">
          <div class="book-desc">תפילין</div>
          <div class="price-currency-container">
              <input currencyMask [options]="{ prefix: ngForm.get(['pricesDeatails', 'priceForTefillin', 'currency']).value === '$' ? '$' : '₪'}" appCurserToEnd type="tel" placeholder="מחיר לתפילין" formControlName="price">
              <button class="currency-button" (click)="toggleCurrency(ngForm.get(['pricesDeatails', 'priceForTefillin', 'currency']))">
                <span class="currency-sign">{{ngForm.get(['pricesDeatails', 'priceForTefillin', 'currency']).value}}</span>
              </button>
          </div>
          <div class="worth-it-buttons-wrapper inner-grid-2">
            <label [ngClass]="{'checked': ngForm.get(['pricesDeatails', 'priceForTefillin', 'worthIt']).value === 'false'}" class="size-checkbox">
              <i class="icofont-close-line"></i>
              <input type="radio" formControlName="worthIt" value='false'
                (click)="isChecked($event, ngForm.get(['pricesDeatails', 'priceForTefillin', 'worthIt']))">
            </label>
            <label [ngClass]="{'checked': ngForm.get(['pricesDeatails', 'priceForTefillin', 'worthIt']).value === 'true'}" class="size-checkbox">
              <i class="icofont-check-alt"></i>
              <input type="radio" formControlName="worthIt" value='true'
                (click)="isChecked($event, ngForm.get(['pricesDeatails', 'priceForTefillin', 'worthIt']))">
            </label>
          </div>
          <p (click)="openDialog(HelperAbstractControlToFormGroup(ngForm.get(['pricesDeatails', 'priceForTefillin'])))" class="note"><i class="icofont-pencil-alt-2"></i></p>
        </div>
        <div formGroupName="priceForMegilla" class="price-for-item inner-grid-2">
          <div class="book-desc">מגילה</div>
          <div class="price-currency-container">
              <input currencyMask [options]="{ prefix: ngForm.get(['pricesDeatails', 'priceForMegilla', 'currency']).value === '$' ? '$' : '₪'}" appCurserToEnd type="tel" placeholder="מחיר למגילה" formControlName="price">
              <button class="currency-button" (click)="toggleCurrency(ngForm.get(['pricesDeatails', 'priceForMegilla', 'currency']))">
                <span class="currency-sign">{{ngForm.get(['pricesDeatails', 'priceForMegilla', 'currency']).value}}</span>
              </button>
          </div>
          <div class="worth-it-buttons-wrapper inner-grid-2">
            <label [ngClass]="{'checked': ngForm.get(['pricesDeatails', 'priceForMegilla', 'worthIt']).value === 'false'}" class="size-checkbox">
              <i class="icofont-close-line"></i>
              <input type="radio" formControlName="worthIt" value='false'
                (click)="isChecked($event, ngForm.get(['pricesDeatails', 'priceForMegilla', 'worthIt']))">
            </label>
            <label [ngClass]="{'checked': ngForm.get(['pricesDeatails', 'priceForMegilla', 'worthIt']).value === 'true'}" class="size-checkbox">
              <i class="icofont-check-alt"></i>
              <input type="radio" formControlName="worthIt" value='true'
                (click)="isChecked($event, ngForm.get(['pricesDeatails', 'priceForMegilla', 'worthIt']))">
            </label>
          </div>
          <p (click)="openDialog(HelperAbstractControlToFormGroup(ngForm.get(['pricesDeatails', 'priceForMegilla'])))" class="note"><i class="icofont-pencil-alt-2"></i></p>
        </div>
      </div>
    </div>
    <div formGroupName="writingDeatails" class="writing-detail-section inner-grid">
      <div formGroupName="writingLevel" class="writing-level inner-grid-2">
        <div>
          <div class="section-title-w-note inner-grid-2">
            <div>דרגת כתיבה</div>
            <p (click)="openDialog(HelperAbstractControlToFormGroup(ngForm.get(['writingDeatails', 'writingLevel'])))" class="note"><i class="icofont-pencil-alt-2"></i></p>
          </div>
        </div>
        <div class="number-button-wrapper inner-grid-2">

          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'writingLevel', 'level']).value === 'זול'}">
              <input type="radio" formControlName="level" value="זול">
              1
            </label>
          </div>
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'writingLevel', 'level']).value === 'פחותה'}">
              <input type="radio" formControlName="level" value="פחותה">
              2
            </label>
          </div>
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'writingLevel', 'level']).value === 'בינוני'}">
              <input type="radio" formControlName="level" value="בינוני">
              3
            </label>
          </div>
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'writingLevel', 'level']).value === 'גבוהה'}">
              <input type="radio" formControlName="level" value="גבוהה">
              4
            </label>
          </div>
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'writingLevel', 'level']).value === 'מיוחד'}">
              <input type="radio" formControlName="level" value="מיוחד">
              5
            </label>
          </div>
        </div>
      </div>
      <div formGroupName="stabilityLevel" class="stability-level inner-grid-2">
        <div class="section-title-w-note inner-grid-2">
          <div>
            יציבות
          </div>
          <p (click)="openDialog(HelperAbstractControlToFormGroup(ngForm.get(['writingDeatails', 'stabilityLevel'])))" ass="note"><i class="icofont-pencil-alt-2"></i></p>
        </div>
        <div class="number-button-wrapper inner-grid-2">
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'stabilityLevel', 'level']).value === '1'}">
              <input type="radio" formControlName="level" value="1">
              1
            </label>
          </div>
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'stabilityLevel', 'level']).value === '2'}">
              <input type="radio" formControlName="level" value="2">
              2
            </label>
          </div>
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'stabilityLevel', 'level']).value === '3'}">
              <input type="radio" formControlName="level" value="3">
              3
            </label>
          </div>
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'stabilityLevel', 'level']).value === '4'}">
              <input type="radio" formControlName="level" value="4">
              4
            </label>
          </div>
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'stabilityLevel', 'level']).value === '5'}">
              <input type="radio" formControlName="level" value="5">
              5
            </label>
          </div>
        </div>
      </div>
      <div formGroupName="eraseLevel" class="erase-level inner-grid-2">

        <div class="section-title-w-note inner-grid-2">
          <div>
            מחיקה
          </div>
          <p (click)="openDialog(HelperAbstractControlToFormGroup(ngForm.get(['writingDeatails', 'eraseLevel'])))" ass="note"><i class="icofont-pencil-alt-2"></i></p>
        </div>
        <div class="number-button-wrapper inner-grid-2">
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'eraseLevel', 'level']).value === '1'}">
              <input type="radio" formControlName="level" value="1">
              1
            </label>
          </div>
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'eraseLevel', 'level']).value === '2'}">
              <input type="radio" formControlName="level" value="2">
              2
            </label>
          </div>
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'eraseLevel', 'level']).value === '3'}">
              <input type="radio" formControlName="level" value="3">
              3
            </label>
          </div>
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'eraseLevel', 'level']).value === '4'}">
              <input type="radio" formControlName="level" value="4">
              4
            </label>
          </div>
          <div>
            <label class="level-checkbox" [ngClass]="{'checked': ngForm.get(['writingDeatails', 'eraseLevel', 'level']).value === '5'}">
              <input type="radio" formControlName="level" value="5">
              5
            </label>
          </div>
        </div>
      </div>
    </div>
    <div formGroupName="writingDeatails" class="sizes-section inner-grid">
      <div class="letter-sizes-wrapper">
        <div formGroupName="letterSizes" class="inner-grid-2">
          <div class="section-title-w-note inner-grid-2">
            <div>
              גודל הכתב
            </div>
            <p (click)="openDialog(HelperAbstractControlToFormGroup(ngForm.get(['writingDeatails', 'letterSizes'])))" class="note"><i class="icofont-pencil-alt-2"></i></p>
          </div>
          <div class="sizes-row inner-grid-2">

            <label [ngClass]="{'checked': ngForm.get(['writingDeatails', 'letterSizes', '17']).value}" class="size-checkbox">
              <input type="checkbox" formControlName="17">
              17
            </label>
            <label [ngClass]="{'checked': ngForm.get(['writingDeatails', 'letterSizes', '24']).value}" class="size-checkbox">
              <input type="checkbox" formControlName="24"> 24
            </label>
            <label [ngClass]="{'checked': ngForm.get(['writingDeatails', 'letterSizes', '30']).value}" class="size-checkbox">
              <input type="checkbox" formControlName="30"> 30
            </label>
            <label [ngClass]="{'checked': ngForm.get(['writingDeatails', 'letterSizes', '36']).value}" class="size-checkbox">
              <input type="checkbox" formControlName="36"> 36
            </label>
          </div>
          <div class="sizes-row inner-grid-2">
            <label [ngClass]="{'checked': ngForm.get(['writingDeatails', 'letterSizes', '40']).value}" class="size-checkbox">
              <input type="checkbox" formControlName="40"> 40
            </label>
            <label [ngClass]="{'checked': ngForm.get(['writingDeatails', 'letterSizes', '42']).value}" class="size-checkbox">
              <input type="checkbox" formControlName="42"> 42
            </label>
            <label [ngClass]="{'checked': ngForm.get(['writingDeatails', 'letterSizes', '45']).value}" class="size-checkbox">
              <input type="checkbox" formControlName="45"> 45
            </label>
            <label [ngClass]="{'checked': ngForm.get(['writingDeatails', 'letterSizes', '48']).value}" class="size-checkbox">
              <input type="checkbox" formControlName="48"> 48
            </label>
          </div>
        </div>
      </div>
    </div>
    <div formGroupName="writingDeatails" class="writing-type-section inner-grid">
      <div formGroupName="writingTypes">
        <div formGroupName="types" class="writing-types inner-grid-2">
          <div class="section-title">
            סוג הכתב
          </div>
          <div>
            <label [ngClass]="{'checked': ngForm.get(['writingDeatails', 'writingTypes', 'types', 'ari']).value}" class="box-checkbox">
              אר"י
              <input type="checkbox" formControlName="ari">
            </label>
          </div>
          <div>
            <label [ngClass]="{'checked': ngForm.get(['writingDeatails', 'writingTypes', 'types', 'beitYosef']).value}" class="box-checkbox">
              בית יוסף
              <input type="checkbox" formControlName="beitYosef">
            </label>
          </div>
          <div>
            <label [ngClass]="{'checked': ngForm.get(['writingDeatails', 'writingTypes', 'types', 'Welish']).value}" class="box-checkbox">
              וועליש
              <input type="checkbox" formControlName="Welish">
            </label>
          </div>
        </div>
      </div>
    </div>
    <div formGroupName="communityDeatails" class="community-section inner-grid">
      <div class="section-title-w-note inner-grid-2">
        <div>
          קהילה
        </div>
        <p (click)="openDialog(HelperAbstractControlToFormGroup(ngForm.get(['communityDeatails'])))" class="note">
          <i class="icofont-pencil-alt-2"></i>
        </p>
      </div>
      <input appCurserSideByLanguaga list="communities" placeholder="התחל להקליד קהילה" formControlName="community" class="full-width">
      <datalist id="communities">
        <option *ngFor="let community of communitiesList" [value]="community">
      </datalist>
    </div>
    <div formGroupName="placeOfWriting" class="writing-location-section inner-grid">


      <div class="section-title-w-note">
        מקום כתיבה
      </div>
      <div>
        <p (click)="openDialog(HelperAbstractControlToFormGroup(ngForm.get(['placeOfWriting'])))" class="note">
          <i class="icofont-pencil-alt-2"></i>
        </p>
      </div>

      <div>
        <label class="box-checkbox" [ngClass]="{'checked': ngForm.get(['placeOfWriting', 'place']).value === 'בית'}">
          בית
          <input type="radio" formControlName="place" value='בית'>
        </label>
      </div>
      <div>
        <label [ngClass]="{'checked': ngForm.get(['placeOfWriting', 'place']).value === 'חדר'}" class="box-checkbox">
          חדר
          <input type="radio" formControlName="place" value='חדר'>
        </label>
      </div>
    </div>
    <div formGroupName="additionalDetails" class="additional-details-section inner-grid">
      <div class="section-title">
        פרטים נוספים
      </div>
      <div>
        <div formGroupName="validCertificate" class="additional-detail inner-grid-2">
          <div class="description">
            תעודה בתוקף
          </div>
          <label [ngClass]="{'checked': ngForm.get(['additionalDetails', 'validCertificate', 'boolean']).value === 'false'}" class="size-checkbox">
            <i class="icofont-close-line"></i>
            <input type="radio" formControlName="boolean" value='false'>
          </label>
          <label [ngClass]="{'checked': ngForm.get(['additionalDetails', 'validCertificate', 'boolean']).value === 'true'}" class="size-checkbox">
            <i class="icofont-check-alt"></i>
            <input type="radio" formControlName="boolean" value='true'>
          </label>
          <p (click)="openDialog(HelperAbstractControlToFormGroup(ngForm.get(['additionalDetails', 'validCertificate'])))" class="note"><i class="icofont-pencil-alt-2"></i></p>
        </div>
        <div formGroupName="hasWritenBefore" class="additional-detail inner-grid-2">
          <div class="description">
            כתב ספרים בעבר
          </div>
          <label [ngClass]="{'checked': ngForm.get(['additionalDetails', 'hasWritenBefore', 'boolean']).value === 'false'}" class="size-checkbox">
            <i class="icofont-close-line"></i>
            <input type="radio" formControlName="boolean" value='false'
              (click)="isChecked($event, ngForm.get(['additionalDetails', 'hasWritenBefore', 'boolean']))">
          </label>
          <label [ngClass]="{'checked': ngForm.get(['additionalDetails', 'hasWritenBefore', 'boolean']).value === 'true'}" class="size-checkbox">
            <i class="icofont-check-alt"></i>
            <input type="radio" formControlName="boolean" value='true'
              (click)="isChecked($event, ngForm.get(['additionalDetails', 'hasWritenBefore', 'boolean']))">
          </label>
          <p (click)="openDialog(HelperAbstractControlToFormGroup(ngForm.get(['additionalDetails', 'hasWritenBefore'])))" class="note"><i class="icofont-pencil-alt-2"></i></p>
        </div>
        <div formGroupName="hasWritenKabala" class="additional-detail inner-grid-2">
          <div class="description">
            כתב קבלה
          </div>
          <label [ngClass]="{'checked': ngForm.get(['additionalDetails', 'hasWritenKabala', 'boolean']).value === 'false'}" class="size-checkbox">
            <i class="icofont-close-line"></i>
            <input type="radio" formControlName="boolean" value='false'
              (click)="isChecked($event, ngForm.get(['additionalDetails', 'hasWritenKabala', 'boolean']))">
          </label>
          <label [ngClass]="{'checked': ngForm.get(['additionalDetails', 'hasWritenKabala', 'boolean']).value === 'true'}" class="size-checkbox">
            <i class="icofont-check-alt"></i>
            <input type="radio" formControlName="boolean" value='true'
              (click)="isChecked($event, ngForm.get(['additionalDetails', 'hasWritenKabala', 'boolean']))">
          </label>
          <p (click)="openDialog(HelperAbstractControlToFormGroup(ngForm.get(['additionalDetails', 'hasWritenKabala'])))" class="note"><i class="icofont-pencil-alt-2"></i></p>
        </div>
        <div formGroupName="voatsInElection" class="additional-detail inner-grid-2">
          <div class="description">
            בחירות
          </div>
          <label [ngClass]="{'checked': ngForm.get(['additionalDetails', 'voatsInElection', 'boolean']).value === 'false'}" class="size-checkbox">
            <i class="icofont-close-line"></i>
            <input type="radio" formControlName="boolean" value='false'
              (click)="isChecked($event, ngForm.get(['additionalDetails', 'voatsInElection', 'boolean']))">
          </label>
          <label [ngClass]="{'checked': ngForm.get(['additionalDetails', 'voatsInElection', 'boolean']).value === 'true'}" class="size-checkbox">
            <i class="icofont-check-alt"></i>
            <input type="radio" formControlName="boolean" value='true'
              (click)="isChecked($event, ngForm.get(['additionalDetails', 'voatsInElection', 'boolean']))">
          </label>
          <p (click)="openDialog(HelperAbstractControlToFormGroup(ngForm.get(['additionalDetails', 'voatsInElection'])))" class="note"><i class="icofont-pencil-alt-2"></i></p>
        </div>
        <div formGroupName="goesToKotel" class="additional-detail inner-grid-2">
          <div class="description">
            הולך לכותל
          </div>
          <label [ngClass]="{'checked': ngForm.get(['additionalDetails', 'goesToKotel', 'boolean']).value === 'false'}" class="size-checkbox">
            <i class="icofont-close-line"></i>
            <input type="radio" formControlName="boolean" value='false'
              (click)="isChecked($event, ngForm.get(['additionalDetails', 'goesToKotel', 'boolean']))">
          </label>
          <label [ngClass]="{'checked': ngForm.get(['additionalDetails', 'goesToKotel', 'boolean']).value === 'true'}" class="size-checkbox">
            <i class="icofont-check-alt"></i>
            <input type="radio" formControlName="boolean" value='true'
              (click)="isChecked($event, ngForm.get(['additionalDetails', 'goesToKotel', 'boolean']))">
          </label>
          <p (click)="openDialog(HelperAbstractControlToFormGroup(ngForm.get(['additionalDetails', 'goesToKotel'])))" class="note">
            <i class="icofont-pencil-alt-2"></i>
          </p>
        </div>
        <div formGroupName="beginnerWriter" class="additional-detail inner-grid-2">
          <div class="description">
            סופר מתחיל
          </div>
          <label [ngClass]="{'checked': ngForm.get(['additionalDetails', 'beginnerWriter', 'boolean']).value === 'false'}" class="size-checkbox">
            <i class="icofont-close-line"></i>
            <input type="radio" formControlName="boolean" value='false'
              (click)="isChecked($event, ngForm.get(['additionalDetails', 'beginnerWriter', 'boolean']))">
          </label>
          <label [ngClass]="{'checked': ngForm.get(['additionalDetails', 'beginnerWriter', 'boolean']).value === 'true'}" class="size-checkbox">
            <i class="icofont-check-alt"></i>
            <input type="radio" formControlName="boolean" value='true'
              (click)="isChecked($event, ngForm.get(['additionalDetails', 'beginnerWriter', 'boolean']))">
          </label>
          <p (click)="openDialog(HelperAbstractControlToFormGroup(ngForm.get(['additionalDetails', 'beginnerWriter'])))" class="note">
            <i class="icofont-pencil-alt-2"></i>
          </p>
        </div>
      </div>
    </div>
    <div formGroupName="additionalDetails">
      <div>
        <div formGroupName="writerLevel" class="writer-level-section inner-grid">
          <div class="section-title span-title">
            <div>
              דרגת סופר
            </div>
          </div>
          <p (click)="openDialog(HelperAbstractControlToFormGroup(ngForm.get(['additionalDetails', 'writerLevel'])))" class="note">
            <i class="icofont-pencil-alt-2"></i>
          </p>
          <div>
            <label [ngClass]="{'checked': ngForm.get(['additionalDetails', 'writerLevel', 'level']).value === '1'}" class="level-checkbox">
              <input type="radio" formControlName="level" value="1">
              1
            </label>
          </div>
          <div>
            <label [ngClass]="{'checked': ngForm.get(['additionalDetails', 'writerLevel', 'level']).value === '2'}" class="level-checkbox">
              <input type="radio" formControlName="level" value="2">
              2
            </label>
          </div>
          <div>
            <label [ngClass]="{'checked': ngForm.get(['additionalDetails', 'writerLevel', 'level']).value === '3'}" class="level-checkbox">
              <input type="radio" formControlName="level" value="3">
              3
            </label>
          </div>
          <div>
            <label [ngClass]="{'checked': ngForm.get(['additionalDetails', 'writerLevel', 'level']).value === '4'}" class="level-checkbox">
              <input type="radio" formControlName="level" value="4">
              4
            </label>
          </div>
          <div>
            <label [ngClass]="{'checked': ngForm.get(['additionalDetails', 'writerLevel', 'level']).value === '5'}" class="level-checkbox">
              <input type="radio" formControlName="level" value="5">
              5
            </label>
          </div>
        </div>
      </div>
    </div>

    <div formArrayName="photos" class="photo-section inner-grid">
      <label for="addPhoto" class="second-title">
        <div class="inner-grid-2 add-section">
          <img src="assets/icons/Add.png" alt="">

          <div>
            הוסף תמונה

          </div>
          <input (change)="onAddPhoto($event)" id="addPhoto" style="display:none;" type="file"
            accept="image/*">
        </div>
      </label>
      <div *ngFor="let photoControl of ngForm.controls.photos['controls']; let i = index;">
        <div (click)="deletePhoto(i)">מחק תמונה</div>
        <img appDoubleTapOpenImg [base64Img]="ngForm.controls.photos.value[i]" [src]="ngForm.controls.photos_620x620.value[i]" class="img">
      </div>
    </div>
    <div class="recording-section inner-grid">
      <label *ngIf="!isRecording" for="click" (click)="startRecording()">
        <div class="inner-grid-2 add-section">
          <div>
            <img src="assets/icons/Add.png" alt="">
          </div>
          <div class="second-title">הוסף תזכורת קולית</div>
        </div>
      </label>
      <div *ngIf="isRecording" (click)="stopRecording()">
        עצור הקלטה
      </div>
      <div>
        <div *ngFor="let recording of ngForm.controls.recordings['controls']; let i = index;">
          <div (click)="deleteRecording(i)">מחק הקלטה</div>
          <app-audio-html5 [recordingUrl]="recording.value"></app-audio-html5>
        </div>
      </div>
    </div>
    <button *ngIf="this.locationWithoutParameters === locationPath.EDIT_WRITER" (click)="onRemove()" class="add-sofer-button">מחק סופר</button>
    <button (click)="onSubmit($event)" class="add-sofer-button">{{ textForSaveButton }}</button>
  </form>
  <!-- <div>{{ ngForm.valid }}</div> -->
</div>
