{
  "name": "ashuris-pwa",
  "version": "1.0.65",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "build:prod": "ng build --configuration production",
    "sourcemap-uploader": "sourcemap-uploader -l -k enM7l7jiFLyNcumtS33c -p EPv4W68SIaYGx6GLUjfa dir -m C:/Users/HP/Documents/GitHub/pwa/a -u https://ashuris.online/ ",
    "deploy:gh": "ng deploy --base-href=\"/ashuris-pwa/\"",
    "build:stats": "ng build --stats-json",
    "analyze": "webpack-bundle-analyzer dist/ashuris-pwa/browser/stats.json"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.10",
    "@angular/common": "^17.3.10",
    "@angular/compiler": "^17.3.10",
    "@angular/compiler-cli": "^17.3.10",
    "@angular/core": "^17.3.10",
    "@angular/forms": "^17.3.10",
    "@angular/localize": "^17.3.10",
    "@angular/platform-browser": "^17.3.10",
    "@angular/platform-browser-dynamic": "^17.3.10",
    "@angular/platform-server": "^17.3.10",
    "@angular/router": "^17.3.10",
    "@angular/service-worker": "^17.3.10",
    "@ffmpeg/ffmpeg": "^0.12.10",
    "@ffmpeg/util": "^0.12.1",
    "@ng-bootstrap/ng-bootstrap": "^16.0.0",
    "@ngrx/effects": "^17.0.0",
    "@ngrx/store": "^17.0.0",
    "@openreplay/tracker": "^14.0.6",
    "@openreplay/tracker-assist": "^9.0.1",
    "@openreplay/tracker-ngrx": "^3.4.9",
    "@swimlane/ngx-datatable": "~20.1.0",
    "animate.css": "^4.1.1",
    "bootstrap": "^5.2.3",
    "libphonenumber-js": "^1.11.8",
    "mp3-mediarecorder": "^4.0.5",
    "ng2-currency-mask": "^13.0.3",
    "ngx-cookie": "^6.0.1",
    "pouchdb": "^9.0.0",
    "pouchdb-upsert": "^2.2.0",
    "rxdb": "^15.33.0",
    "rxjs": "^7.8.1",
    "tslib": "^2.7.0",
    "uuid": "^8.3.0",
    "vconsole": "^3.15.1",
    "webpfy": "^1.0.9",
    "worker-pouch": "^2.1.0",
    "zone.js": "~0.14.6"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.8",
    "@angular/cli": "^17.3.8",
    "@ngrx/schematics": "^17.2.0",
    "@ngrx/store-devtools": "^17.0.0",
    "@ngx-builders/analyze": "^4.0.0",
    "@openreplay/sourcemap-uploader": "^3.0.8",
    "@types/dom-mediacapture-record": "^1.0.19",
    "@types/googlemaps": "^3.43.3",
    "@types/jasmine": "^3.6.0",
    "@types/jasminewd2": "^2.0.3",
    "@types/node": "^18.19.34",
    "@types/pouchdb": "^6.4.2",
    "@types/pouchdb-upsert": "^2.2.9",
    "@types/uuid": "^8.3.0",
    "@typescript-eslint/eslint-plugin": "^7.12.0",
    "@typescript-eslint/parser": "^7.12.0",
    "angular-cli-ghpages": "^2.0.0",
    "codelyzer": "^6.0.2",
    "eslint": "^8.57.0",
    "typescript": "5.4.5"
  }
}
