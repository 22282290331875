import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Price, Writer } from '../../interfaces';
import { DomSanitizer } from '@angular/platform-browser';
import {
  preventDefaultAndStopPropagation,
  thereAreDetailsInGivenObject,
  shareButton,
  addAreaCodeForIsraliNumbers,
} from 'src/app/utils/utils';
import { FirebaseService } from 'src/app/firebase.service';
import { WriterService } from 'src/app/writer.service';

@Component({
  selector: 'app-writer-details',
  templateUrl: './writer-details.component.html',
  styleUrls: ['./writer-details.component.css']
})
export class WriterDetailsComponent implements OnInit {
  thereAreDetailsInGivenObject = thereAreDetailsInGivenObject;
  writer: Writer;
  priceForTorahScroll: { pricePerPage: Price, priceForScroll: Price };
  writerAddress: string;
  openMenuStatus = {
    pricesDeatails: false,
    writingDeatails: false,
    additionalDeatails: false,
    images: false,
    recordings: false,
  };
  shareButton = shareButton;
  addAreaCodeForIsraliNumbers = addAreaCodeForIsraliNumbers;

  dialogContent = null;
  preventDefaultAndStopPropagation = preventDefaultAndStopPropagation;

  @ViewChild('mapContainer', { static: false }) gmap: ElementRef;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly writerService: WriterService,
    public readonly sanitizer: DomSanitizer,
    private readonly firebaseService: FirebaseService,
  ) { }

  async ngOnInit(): Promise<void> {
    const id = this.activatedRoute.snapshot.queryParamMap.get('id');
    this.writerService.getWriter(id).then(async writer => {
      this.writer = structuredClone(writer) as Writer;
      this.writerAddress = `${this.writer.city}+${this.writer.street}+${this.writer.streetNumber}`;
      const price = { ...this.writer?.pricesDeatails?.priceForTorahScroll };
      if (price.price) {
        this.priceForTorahScroll = {

          pricePerPage:
          {
            ...price,
            price: this.writer.pricesDeatails.isPricePerPage !== 'מחיר לספר תורה'
              ? this.writer.pricesDeatails.priceForTorahScroll.price
              : Math.round((this.writer.pricesDeatails.priceForTorahScroll.price - 8700) / 245),
          },
          priceForScroll: {
            ...price,
            price: this.writer.pricesDeatails.isPricePerPage !== 'מחיר לספר תורה'
              ? Math.round((this.writer.pricesDeatails.priceForTorahScroll.price * 245) + 8700)
              : this.writer.pricesDeatails.priceForTorahScroll.price,
          }
        };
      }
      this.writer.photos_620x620 = await this.firebaseService.getFromAvailableResources(this.writer.photos_620x620);
      // this.writer.recordings = await this.firebaseService.getFromAvailableResources(this.writer.recordings);
    });
  }


  editWriter() {
    this.router.navigate(['/edit-writer'], { queryParams: { id: this.writer._id } });
  }

  closeMenus(menuToOpen: keyof WriterDetailsComponent['openMenuStatus']) {
    const menuToOpenStatus = this.openMenuStatus[menuToOpen];

    this.openMenuStatus = {
      pricesDeatails: false,
      writingDeatails: false,
      additionalDeatails: false,
      images: false,
      recordings: false,
    };

    this.openMenuStatus[menuToOpen] = !menuToOpenStatus;
  }

  openDialog(event: Event, content: string) {
    preventDefaultAndStopPropagation(event);
    this.dialogContent = content;
  }

  closeDialog() {
    this.dialogContent = null;
  }

  writersInRoomList(event: Event): void {
    preventDefaultAndStopPropagation(event);

    const city = this.writer.city;
    const street = this.writer.street;
    const streetNumber = this.writer.streetNumber;

    this.router.navigate(['writers-in-room-list'], { queryParams: { city, street, streetNumber } });
  }

  getLetterSizesString(
    letterSizes: { 17: boolean; 24: boolean; 30: boolean; 36: boolean; 40: boolean; 42: boolean; 45: boolean; 48: boolean; }
  ) {
    const letterSizesArray = [];
    Object.entries(letterSizes).forEach(letterSize => {
      if (letterSize[1] === true) {
        letterSizesArray.push(letterSize[0]);
      }
    });
    return letterSizesArray.join(' ,');
  }

  errorEventCall(idUrl: string, index: number) {
    this.writer.photos_620x620[index] = '';
  }

}
