import { environment } from 'src/environments/environment';

export enum BooksOrDealers {
  books = 'books',
  dealers = 'dealers',
}

export enum RemoveItem {
  book = 'ספר',
  dealer = 'סוחר',
  writer = 'סופר',
  img = 'תמונה',
  recording = 'הקלטה',
}

export enum LocationPath {
  BOOKS_ADVANCED_SEARCH = '/books-advanced-search',
  BOOKS_ADVANCED_SEARCH_RESULT = '/books-search-result',
  WRITERS_ADVANCED_SEARCH = '/writers-advanced-search',
  WRITERS_ADVANCED_SEARCH_RESULT = '/writers-search-result',
  BOOK_LIST_SCREEN = '/book-list-screen',
  DEALER_BOOK_LIST = '/dealer-book-list',
  WRITERS_IN_ROOM_LIST = '/writers-in-room-list',
  WRITERS_LIST_SCREEN = '/writers-list-screen',
  CREATE_WRITER = '/create-writer',
  CREATE_BOOK = '/create-book',
  EDIT_WRITER = '/edit-writer',
  EDIT_DEALER = '/edit-dealer',
  EDIT_BOOK = '/edit-book',
  CREATE_DEALER_FOR_BOOK = '/create-dealer-for-book',
  WRITER_DETAILS = '/writer-details',
  SAVE_DEALER_FOR_BOOK = '/save-dealer-for-book',
  REMOVE_ITEM = '/home-page-remove-item',
}

export enum SearchFor {
  WRITERS = 'WRITERS',
  BOOKS = 'BOOKS',
}

export enum LocalDbNames {
  IMAGES_TO_UPLOAD_DB = 'sync_db_v2',
  WRITERS = 'local_writers_db',
  DEALERS = 'local_dealers_db',
  BOOKS = 'local_books_db',
  GENERAL = 'local_general_db',
}

export const RemoteDbNames = {
  WRITERS: `${environment.host}/couch/writers___remote/`,
  DEALERS: `${environment.host}/couch/dealers___remote/`,
  BOOKS: `${environment.host}/couch/books___remote/`,
  GENERAL: `${environment.host}/couch/general___remote/`,
} as const;

export type RemoteDbNames = typeof RemoteDbNames[keyof typeof RemoteDbNames];
