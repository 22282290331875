<app-note-dialog-output *ngIf="dialogContent !== null" (closeDialog)="closeDialog()" [content]="dialogContent">
</app-note-dialog-output>
<div class="page-wrapper">
  <div class="writer-details-wrapper page-grid" *ngIf="dealer">
    <div class="top-section">
      <i *ngIf="!dealer.profileImage" class="icofont-user-alt-7"></i>
      <img *ngIf="dealer.profileImage" [src]="dealer.profileImage" alt="" class="profile-img">
      <div class="name-wrapper">
        <p class="dealer-name">{{ dealer.lastName }} {{ dealer.firstName }}</p>
        <p class="dealer-location" *ngIf="dealer.street && dealer.city">{{ dealer.street }}, {{ dealer.city }}
        </p>
      </div>
      <div *ngIf="dealer.note" (click)="openDialog($event, dealer.note)">
        <i class="icofont-exclamation-circle"></i>
      </div>
      <button type="button" (click)="editDealer()" class="edit-button">ערוך</button>
    </div>
    <div class="basic-details-section">
      <a [href]="'tel:' + addAreaCodeForIsraliNumbers(dealer.telephone)" class="basic-detail .inner-grid" *ngIf="dealer.telephone">
        <i class="icofont-phone"></i>
        <div>
          <div dir="ltr" class="information">{{ dealer.telephone | phone }}</div>
          <div class="description">טלפון</div>
        </div>
      </a>
      <a [href]="'tel:' + addAreaCodeForIsraliNumbers(dealer.secondTelephone)" class="basic-detail .inner-grid" *ngIf="dealer.secondTelephone">
        <i class="icofont-phone"></i>
        <div>
          <div dir="ltr" class="information">{{ dealer.secondTelephone | phone }}</div>
          <div class="description">טלפון נוסף</div>
        </div>
      </a>
      <a href="mailto:{{dealer.email}}" class="basic-detail .inner-grid " *ngIf="dealer.email ">
        <i class="icofont-envelope "></i>
        <div>
          <div class="information ">{{ dealer.email }}</div>
          <div class="description ">אימייל</div>
        </div>
      </a>
      <div class="basic-detail .inner-grid " *ngIf="dealer.city ">
        <i class="icofont-location-pin "></i>
        <div>
          <div class="information">
            <a href="http://maps.google.com/?q={{ dealerAddress }}">{{ dealer.street }} {{ dealer.streetNumber }} <span
                *ngIf="dealer.apartmentNumber ">דירה {{ dealer.apartmentNumber }}</span> {{ dealer.city }}</a>
          </div>
          <div class="description ">כתובת</div>
        </div>
      </div>
      <div class="date-stamp-wrapper">
        <div *ngIf="dealer.creationDate" class="date-stamp"> Creation Date: {{dealer.creationDate | date: 'medium'}}
        </div>
        <div *ngIf="dealer.editDate" class="date-stamp"> Edit Date: {{dealer.editDate | date:'medium'}}</div>
        <div *ngIf="dealer.editorUserName" class="date-stamp"> edited by: {{dealer.editorUserName}}</div>
      </div>
    </div>
  </div>
</div>