import { Component, ElementRef, Renderer2 } from '@angular/core';
import { FirebaseService } from '../firebase.service';
import packageJson from '../../../package.json';

@Component({
  selector: 'app-status-strip',
  templateUrl: './status-strip.component.html',
  styleUrls: ['./status-strip.component.scss'],
})
export class StatusStripComponent {
  isExpanded = false;
  version = packageJson.version;

  constructor(private el: ElementRef, private renderer: Renderer2, public firebaseService: FirebaseService) { }

  onStripClick() {
    this.isExpanded = !this.isExpanded;
    if (this.isExpanded) {
      this.renderer.addClass(this.el.nativeElement.querySelector('.strip'), 'expanded');
    } else {
      this.renderer.removeClass(this.el.nativeElement.querySelector('.strip'), 'expanded');
    }
  }
}