import { AfterViewInit, Component, Input } from '@angular/core';
import { base64ToBlob, blobToObjectUrl, getDuration } from '../utils/utils';
@Component({
  selector: 'app-audio-html5',
  templateUrl: './audio-html5.component.html',
  styleUrls: ['./audio-html5.component.css']
})
export class AudioHTML5Component implements AfterViewInit {
  @Input() recordingUrl: string;
  recordingState = {
    audio: new Audio(),
    isListening: false,
    duration: 0,
    currentTime: 0,
  };

  endedListener: (audio: HTMLAudioElement) => void = (audio: HTMLAudioElement) => {
    this.recordingState.isListening = false;
    audio.load();
  }

  timeUpdateListener = () => {
    this.recordingState.currentTime = Math.floor(this.recordingState.audio.currentTime);
  }

  async ngAfterViewInit() {
    try {
      const audioObjectUrl = blobToObjectUrl(await base64ToBlob(this.recordingUrl));
      const audio = new Audio(audioObjectUrl);
      const duration = await getDuration(audio);
      this.recordingState = {
        audio,
        isListening: false,
        duration: Math.floor(duration),
        currentTime: 0
      };
      audio.addEventListener('ended', this.endedListener.bind(this, audio));
      audio.addEventListener('timeupdate', this.timeUpdateListener);
    } catch (error) {
      console.error('Error initializing audio:', error);
    }
  }

  togglePlayback() {
    if (this.recordingState.isListening) {
      this.recordingState.isListening = false;
      this.recordingState.audio.pause();
    } else {
      this.recordingState.isListening = true;
      this.recordingState.audio.play();
    }
  }

  ngOnDestroy() {
    this.recordingState.audio.removeEventListener('ended', this.endedListener.bind(this.recordingState.audio));
    this.recordingState.audio.removeEventListener('timeupdate', this.timeUpdateListener);
  }
}