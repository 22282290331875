import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormArray, AbstractControl } from '@angular/forms';
import { DBService } from '../../stitch-service.service';
import { areYouSureYouWantToRemove, blobToBase64 } from '../../utils/utils';
import { GoogleMapsService } from '../../google-maps-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { RecordingService } from '../../recording.service';
import { Writer, Address } from '../../interfaces';
import { Location } from '@angular/common';
import { LocalDbNames, LocationPath, RemoveItem } from 'src/app/enums';
import { AuthService } from 'src/app/auth.service';
import { WriterService } from 'src/app/writer.service';
import webpfy from 'webpfy';

@Component({
  selector: 'app-edit-writer',
  templateUrl: './edit-writer.component.html',
  styleUrls: ['./edit-writer.component.css']
})
export class EditWriterComponent implements OnInit {
  locationPath: typeof LocationPath = LocationPath;

  writer: Writer;

  citiesList: string[];

  communitiesList: string[];

  ngForm: UntypedFormGroup;
  map: google.maps.Map;
  isRecording = false;
  hasRecord = false;
  dialogFormGroup: UntypedFormGroup = null;
  textForSaveButton = 'הוסף סופר למאגר';
  editOrCreatePage: boolean;
  locationWithoutParameters: string;

  constructor(
    private readonly pouchDbService: DBService,
    private readonly googleMapsService: GoogleMapsService,
    public recordingService: RecordingService,
    public sanitizer: DomSanitizer,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly location: Location,
    private readonly authService: AuthService,
    private readonly writerService: WriterService,
  ) { }

  async ngOnInit() {
    this.ngForm = new UntypedFormGroup({
      creationDate: new UntypedFormControl(new Date().getTime(), [
        Validators.required,
      ]),
      editDate: new UntypedFormControl(new Date().getTime(), [
        Validators.required,
      ]),
      editorUserName: new UntypedFormControl(this.authService.getCookieUserName()),
      note: new UntypedFormControl('', [
        // Validators.required,
      ]),
      firstName: new UntypedFormControl('', [
        Validators.required,
      ]),
      lastName: new UntypedFormControl('', [
        Validators.required,
      ]),
      telephone: new UntypedFormControl('', [
        // Validators.required,
        // Validators.pattern('^[0-9]*$'),
        // Validators.minLength(9),
      ]),
      secondTelephone: new UntypedFormControl('', [
        // Validators.required,
        // Validators.pattern('^[0-9]*$'),
        // Validators.minLength(9),
      ]),
      email: new UntypedFormControl('', [
        // Validators.required,
        // Validators.email,
      ]),
      city: new UntypedFormControl('', [
        // Validators.required,
      ]),
      street: new UntypedFormControl('', [
        // Validators.required,
      ]),
      coordinates: new UntypedFormControl(),
      streetNumber: new UntypedFormControl('', [
        // Validators.required,
      ]),
      apartmentNumber: new UntypedFormControl('', [
        // Validators.required,
      ]),
      profileImage: new UntypedFormControl('', [
        // Validators.required,
      ]),
      startDate: new UntypedFormGroup({
        gregorianDate: new UntypedFormControl('', [
          // Validators.required,
        ]),
        hebrewDateInWords: new UntypedFormControl('', [
          // Validators.required,
        ]),
      }),
      lengthOfWritingBook: new UntypedFormGroup({
        length: new UntypedFormControl('', [
          // Validators.required
        ]),
        note: new UntypedFormControl('', [
          // Validators.required,
        ]),
      }),
      isAppropriate: new UntypedFormGroup({
        level: new UntypedFormControl('', [
          Validators.required
        ]),
        note: new UntypedFormControl('', [
          // Validators.required,
        ]),
      }),
      pricesDeatails: new UntypedFormGroup({
        isPricePerPage: new UntypedFormControl('מחיר לעמוד'),
        priceForTorahScroll: new UntypedFormGroup({
          price: new UntypedFormControl('', [
            // Validators.required
          ]),
          currency: new UntypedFormControl('₪', [
            // Validators.required
          ]),
          worthIt: new UntypedFormControl('', [
            // Validators.required,
          ]),
          note: new UntypedFormControl('', [
            // Validators.required,
          ]),
        }),
        priceForMezuzah: new UntypedFormGroup({
          price: new UntypedFormControl('', [
            // Validators.required
          ]),
          currency: new UntypedFormControl('₪', [
            // Validators.required
          ]),
          worthIt: new UntypedFormControl('', [
            // Validators.required,
          ]),
          note: new UntypedFormControl('', [
            // Validators.required,
          ]),
        }),
        priceForTefillin: new UntypedFormGroup({
          price: new UntypedFormControl('', [
            // Validators.required
          ]),
          currency: new UntypedFormControl('₪', [
            // Validators.required
          ]),
          worthIt: new UntypedFormControl('', [
            // Validators.required,
          ]),
          note: new UntypedFormControl('', [
            // Validators.required,
          ]),
        }),
        priceForMegilla: new UntypedFormGroup({
          price: new UntypedFormControl('', [
            // Validators.required
          ]),
          currency: new UntypedFormControl('₪', [
            // Validators.required
          ]),
          worthIt: new UntypedFormControl('', [
            // Validators.required,
          ]),
          note: new UntypedFormControl('', [
            // Validators.required,
          ]),
        }),
      }),
      writingDeatails: new UntypedFormGroup({
        letterSizes: new UntypedFormGroup({
          17: new UntypedFormControl(false, [
            // Validators.required,
          ]),
          24: new UntypedFormControl(false, [
            // Validators.required,
          ]),
          30: new UntypedFormControl(false, [
            // Validators.required,
          ]),
          36: new UntypedFormControl(false, [
            // Validators.required,
          ]),
          40: new UntypedFormControl(false, [
            // Validators.required,
          ]),
          42: new UntypedFormControl(false, [
            // Validators.required,
          ]),
          45: new UntypedFormControl(false, [
            // Validators.required,
          ]),
          48: new UntypedFormControl(false, [
            // Validators.required,
          ]),
          note: new UntypedFormControl('', [
            // Validators.required,
          ]),
        }),
        writingLevel: new UntypedFormGroup({
          level: new UntypedFormControl('', [
            // Validators.required,
          ]),
          note: new UntypedFormControl('', [
            // Validators.required,
          ]),
        }),
        stabilityLevel: new UntypedFormGroup({
          level: new UntypedFormControl('', [
            // Validators.required,
          ]),
          note: new UntypedFormControl('', [
            // Validators.required,
          ]),
        }),
        eraseLevel: new UntypedFormGroup({
          level: new UntypedFormControl('', [
            // Validators.required,
          ]),
          note: new UntypedFormControl('', [
            // Validators.required,
          ]),
        }),
        writingTypes: new UntypedFormGroup({
          types: new UntypedFormGroup({
            ari: new UntypedFormControl(false, [
              // Validators.required,
            ]),
            beitYosef: new UntypedFormControl(false, [
              // Validators.required,
            ]),
            Welish: new UntypedFormControl(false, [
              // Validators.required,
            ]),
          }),
          note: new UntypedFormControl('', [
            // Validators.required,
          ]),
        }),
      }),
      communityDeatails: new UntypedFormGroup({
        community: new UntypedFormControl('', [
          // Validators.required
        ]),
        note: new UntypedFormControl('', [
          // Validators.required,
        ]),
      }),
      placeOfWriting: new UntypedFormGroup({
        place: new UntypedFormControl('', [
          // Validators.required,
        ]),
        note: new UntypedFormControl('', [
          // Validators.required,
        ]),
      }),
      additionalDetails: new UntypedFormGroup({
        validCertificate: new UntypedFormGroup({
          boolean: new UntypedFormControl('', []),
          note: new UntypedFormControl('', []),
        }),
        hasWritenBefore: new UntypedFormGroup({
          boolean: new UntypedFormControl('', [
            // Validators.required,
          ]),
          note: new UntypedFormControl('', [
            // Validators.required,
          ]),
        }),
        hasWritenKabala: new UntypedFormGroup({
          boolean: new UntypedFormControl('', [
            // Validators.required,
          ]),
          note: new UntypedFormControl('', [
            // Validators.required,
          ]),
        }),
        voatsInElection: new UntypedFormGroup({
          boolean: new UntypedFormControl('', [
            // Validators.required,
          ]),
          note: new UntypedFormControl('', [
            // Validators.required,
          ]),
        }),
        goesToKotel: new UntypedFormGroup({
          boolean: new UntypedFormControl('', [
            // Validators.required,
          ]),
          note: new UntypedFormControl('', [
            // Validators.required,
          ]),
        }),
        beginnerWriter: new UntypedFormGroup({
          boolean: new UntypedFormControl('', [
            // Validators.required,
          ]),
          note: new UntypedFormControl('', [
            // Validators.required,
          ]),
        }),
        writerLevel: new UntypedFormGroup({
          level: new UntypedFormControl('', [
            // Validators.required,
          ]),
          note: new UntypedFormControl('', [
            // Validators.required,
          ]),
        }),
      }),
      isWritingRegularly: new UntypedFormGroup({
        boolean: new UntypedFormControl(false),
      }),
      photos: new UntypedFormArray([]),
      photos_620x620: new UntypedFormArray([]),
      recordings: new UntypedFormArray([]),
    });

    this.locationWithoutParameters = this.location.path().split('?')[0];
    this.editOrCreatePage =
      (this.locationWithoutParameters === LocationPath.EDIT_WRITER) || (this.locationWithoutParameters === LocationPath.CREATE_WRITER);
    if (this.locationWithoutParameters === LocationPath.EDIT_WRITER) {
      const id = this.activatedRoute.snapshot.queryParamMap.get('id');
      this.writer = { ...await this.writerService.getWriter(id) } as Writer;

      const recordingsArray = this.ngForm.controls.recordings as UntypedFormArray;
      this.writer.recordings.forEach(recording => recordingsArray.push(new UntypedFormControl(recording)));

      const photosArray = this.ngForm.controls.photos as UntypedFormArray;
      this.writer.photos?.forEach(photo => photosArray.push(new UntypedFormControl(photo)));

      const photos620x620 = this.ngForm.controls.photos_620x620 as UntypedFormArray;
      this.writer.photos_620x620?.forEach(photo => photos620x620.push(new UntypedFormControl(photo)));

      this.ngForm.patchValue(this.writer);
      this.textForSaveButton = 'שמור שינויים';
    }

    this.pouchDbService.getCities()
      .then(citiesDoc => {
        this.citiesList = citiesDoc.map(cityDoc => cityDoc.itemName);
      });

    this.pouchDbService.getCommunities()
      .then(communitiesDoc => {
        this.communitiesList = communitiesDoc.map(communityDoc => communityDoc.itemName);
      });

  }

  openDialog(formGroup: UntypedFormGroup) {
    this.dialogFormGroup = formGroup;
  }

  closeDialogAndDeleteChanges(noteValueBeforeChanges: string) {
    this.dialogFormGroup.controls.note.setValue(noteValueBeforeChanges);
    this.dialogFormGroup = null;
  }

  HelperAbstractControlToFormGroup(abstractControl): UntypedFormGroup {
    return abstractControl as UntypedFormGroup;
  }

  closeDialog() {
    this.dialogFormGroup = null;
  }

  async onAddProfileImage(event: Event): Promise<void> {
    try {
      const element = event.target as HTMLInputElement;
      const file = element.files ? element.files[0] : null;
      if (!file) {
        return;
      }
      const base64File = await blobToBase64(file);
      this.ngForm.controls.profileImage.setValue(base64File);
    } catch (err) {
      console.error('Error processing profile image:', err);
    }
  }

  async onAddPhoto(event: Event): Promise<void> {
    try {
      const element = event.target as HTMLInputElement;
      const file = element.files ? element.files[0] : null;
      if (!file) {
        return;
      }
      const { webpBlob } = await webpfy({ image: file, quality: 80 });
      const base64File = await blobToBase64(webpBlob);
      const photosArray = this.ngForm.controls.photos as UntypedFormArray;
      photosArray.push(new UntypedFormControl(base64File));
      const photos620x620Array = this.ngForm.controls.photos_620x620 as UntypedFormArray;
      photos620x620Array.push(new UntypedFormControl(base64File));
    } catch (err) {
      console.error('Error processing photo:', err);
    }
  }

  startRecording() {
    this.recordingService.startRecording()
      .then(() => {
        this.isRecording = true;
      })
      .catch(err => {
        console.log(err);
      });
  }

  stopRecording() {
    this.isRecording = false;
    this.hasRecord = true;

    this.recordingService.stopRecording()
      .then((audioBlob: Blob) => {
        this.recordingService.convertRecordingToBase64(audioBlob)
          .then((base64data: string) => {
            const recordingsArray = this.ngForm.controls.recordings as UntypedFormArray;
            recordingsArray.push(new UntypedFormControl('data:audio/mp3;base64' + base64data.split('base64')[1]));
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  deleteRecording(index: number) {
    if (areYouSureYouWantToRemove(RemoveItem.recording)) {
      const recordingsArray = this.ngForm.controls.recordings as UntypedFormArray;
      recordingsArray.removeAt(index);
    }
  }

  deletePhoto(index: number) {
    if (areYouSureYouWantToRemove(RemoveItem.img)) {
      const photosArray = this.ngForm.controls.photos as UntypedFormArray;
      photosArray.removeAt(index);
    }
  }

  isChecked(event, formControl: AbstractControl) {
    if (event.target.value === formControl.value) {
      formControl.setValue('');
    }
  }

  onRemove() {
    if (areYouSureYouWantToRemove(RemoveItem.writer)) {
      this.pouchDbService.removeItem(LocalDbNames.WRITERS, this.writer);
    }
  }

  toggleCurrency(currencyControl: AbstractControl) {
    if (currencyControl.value === '$') {
      currencyControl.setValue('₪');
    } else {
      currencyControl.setValue('$');
    }
  }

  onSubmit(event) {
    if (this.ngForm.valid) {
      const div = (event.target as HTMLDivElement);
      div.classList.add('mirror-rotate');
      setTimeout(() => {
        div.classList.remove('mirror-rotate');
      }, 2000);
      if (this.locationWithoutParameters === LocationPath.CREATE_WRITER) {
        this.writerService.createWriter({ ...this.ngForm.value });
      } else if (this.locationWithoutParameters === LocationPath.EDIT_WRITER) {
        this.writerService.createWriter({ ...this.writer, ...this.ngForm.value });
      }
      this.router.navigate(['/writers-list-screen']);
    } else {
      alert('יש למלא שם פרטי, שם משפחה ומידת התאמה');
    }
  }

  fillLocation() {
    this.googleMapsService.setAddressThroghGoogleMaps().then((address: Address) => {
      this.ngForm.controls.city.setValue(address.city);
      this.ngForm.controls.street.setValue(address.street);
      this.ngForm.controls.streetNumber.setValue(address.streetNumber);
      this.ngForm.controls.coordinates.setValue(address.coordinates);
    });
  }

  curserToEnd(event) {
    const input = event.target as HTMLInputElement;
    const inputLength = input.value.length;
    input.setSelectionRange(inputLength, inputLength);
  }

  updateEditDate() {
    this.ngForm.controls.editDate.setValue(new Date().getTime());
    this.ngForm.controls.editorUserName.setValue(this.authService.getCookieUserName());
  }

}
