import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import { base64ToBlob } from './utils/utils';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  constructor(private readonly firebaseService: FirebaseService) { }

  async uploadBase64ImagesAndGetUrls(base64: string[], name: string, id: string, extension: string) {
    try {
      return await Promise.all(base64.map(async (photo) => {
        if (photo.indexOf('http') === 0) {
          return photo;
        } else {
          const imgBlob = await base64ToBlob(photo);
          return await this.firebaseService.addImagesToQueueOfUploads(imgBlob, `${name}_${id}`, extension);
        }
      }));
    } catch (error) {
      console.error('Error uploading base64 and replacing with storage URLs:', error);
      return base64;
    }
  }
}