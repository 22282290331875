/// <reference path="global.d.ts" />
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import OpenReplay from '@openreplay/tracker';
import trackerNgRx from '@openreplay/tracker-ngrx';
import trackerAssist from '@openreplay/tracker-assist';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { OpenReplayService } from './app/openReplay.service';
import packageJosn from '../package.json';

// import VConsole from 'vconsole';

if (environment.production) {
  enableProdMode();
}

document.title = `Ashuris ${packageJosn.version}`;
console.log('Ashuris', packageJosn.version);

const tracker = new OpenReplay({
  projectKey: "EPv4W68SIaYGx6GLUjfa",
});
const metaReducers = [tracker.use(trackerNgRx())];
tracker.use(trackerAssist({}));
tracker.start();


document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
  .then((moduleRef) => {
    const applicationRef = moduleRef.injector.get(OpenReplayService);
    applicationRef.tracer = tracker
  })
    .catch(err => console.error(err));
});
