import { Injectable } from '@angular/core';
import { DBService } from './stitch-service.service';

@Injectable({
  providedIn: 'root',
})
export class CurrencyConversionService {
  private exchangeRate: number;

  constructor(private exchangeRateService: DBService) {
    this.fetchExchangeRate();
  }

  private async fetchExchangeRate() {
    this.exchangeRate = await this.exchangeRateService.getExchangeRates();
  }

  convertToUSD(price: number, currency: '$' | '₪'): number {
    if (currency === '$') {
      return price; // return the price as is if it's already in dollars
    }
    return price / this.exchangeRate;
  }

  convertFromUSD(price: number): number {
    return price * this.exchangeRate;
  }
}