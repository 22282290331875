import { Injectable } from '@angular/core';
import { Book, GeneralDB } from './interfaces';
import { FileUploadService } from './file-upload.service';
import { v4 as uuidv4 } from 'uuid';
import { UtilityService } from './utility.service';
import { DealerService } from './dealer.service';
import { DBService } from './stitch-service.service';
import { DeepReadonlyObject, RxCollection } from 'rxdb';

@Injectable({
  providedIn: 'root'
})
export class BookService {
  private booksCollection: RxCollection<Book>;
  private generalCollection: RxCollection<GeneralDB>;

  constructor(
    private readonly dbService: DBService,
    private readonly fileUploadService: FileUploadService,
    private readonly utilityService: UtilityService,
    private readonly dealerService: DealerService,
  ) {
    this.initCollections();
  }

  private async initCollections() {
    await this.dbService.dbReady;
    this.booksCollection = this.dbService.localBooksDB;
    this.generalCollection = this.dbService.localGeneralDB;
  }

  async createBook(book: Book, dealerId: string) {
    await this.dbService.dbReady;
    try {
      await this.utilityService.createCommunity(book.communityDeatails.community);
      await this.createParchment(book.writingDeatails.parchmentType.type);

      const bookClone = JSON.parse(JSON.stringify(book)) as Book;

      if (bookClone._id) {
        bookClone.photos = await this.fileUploadService.uploadBase64ImagesAndGetUrls(bookClone.photos, bookClone.name, bookClone._id, 'jpg');
        bookClone.photos_620x620 = bookClone.photos.map(photoUrl => photoUrl.replace('.jpg', '_620x620.jpg'));
        bookClone.recordings = await this.fileUploadService.uploadBase64ImagesAndGetUrls(bookClone.recordings, bookClone.name, bookClone._id, 'mp3');
        
        const oldBook = await this.getBookById(bookClone._id);
        if (oldBook) {
          await this.booksCollection.upsert({ ...oldBook, ...bookClone } as Book);
          if (oldBook.dealer) {
            await this.dealerService.removeBookFromDealer(oldBook.dealer, bookClone._id);
          }
          if (dealerId) {
            await this.dealerService.addBookToDealer(bookClone._id, dealerId);
          }
        } else {
          console.warn('Old book not found for update:', bookClone._id);
        }
      } else {
        bookClone._id = uuidv4();
        bookClone.photos = await this.fileUploadService.uploadBase64ImagesAndGetUrls(bookClone.photos, bookClone.name, bookClone._id, 'jpg');
        bookClone.photos_620x620 = bookClone.photos.map(photoUrl => photoUrl.replace('.jpg', '_620x620.jpg'));
        bookClone.recordings = await this.fileUploadService.uploadBase64ImagesAndGetUrls(bookClone.recordings, bookClone.name, bookClone._id, 'mp3');
        await this.booksCollection.insert(bookClone);
        await this.dealerService.addBookToDealer(bookClone._id, dealerId);
      }
    } catch (error) {
      console.error('Error creating book:', error);
    }
  }

  async getBooks(): Promise<Book[]> {
    await this.dbService.dbReady;
    try {
      const result = await this.booksCollection.find().exec();
      return result.map(doc => doc.toJSON()) as Book[];
    } catch (error) {
      console.error('Error getting books:', error);
      return [];
    }
  }

  async getBookById(id: string): Promise<DeepReadonlyObject<Book>> {
    await this.dbService.dbReady;
    try {
      const result = await this.booksCollection.findOne(id).exec();
      return result.toJSON();
    } catch (error) {
      console.error('Error getting book by ID:', error);
      return null;
    }
  }

  async createParchment(parchment: string) {
    await this.dbService.dbReady;
    try {
      const existingParchment = await this.generalCollection.findOne({ selector: { type: 'parchment', itemName: parchment } }).exec();
      if (!existingParchment) {
        await this.generalCollection.insert({ itemName: parchment, type: 'parchment' });
      }
    } catch (error) {
      console.error('Error creating parchment:', error);
    }
  }

  async getBookReminders(levelOfUrgency: number) {
    await this.dbService.dbReady;
    try {
      const result = await this.booksCollection.find({ selector: { levelOfUrgency } }).exec();
      return result.map(doc => doc.toJSON());
    } catch (error) {
      console.error('Error getting book reminders:', error);
      return [];
    }
  }

  async getBooksBySoldCondition(isSold: boolean) {
    await this.dbService.dbReady;
    try {
      const result = await this.booksCollection.find({ selector: { 'isSold.boolean': isSold } }).exec();
      return result.map(doc => doc.toJSON());
    } catch (error) {
      console.error('Error getting books by sold condition:', error);
      return [];
    }
  }
}