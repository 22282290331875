export const writerSchema = {
    title: 'writers schema',
    version: 0,
    description: 'describes a writer',
    type: 'object',
    primaryKey: '_id',
    properties: {
        _id: { type: 'string', maxLength: 100 },
        editorUserName: { type: 'string' },
        creationDate: { type: 'number' },
        editDate: { type: 'number' },
        photos_620x620: { type: 'array', items: { type: 'string' } },
        note: { type: 'string' },
        levelOfUrgency: { type: 'number' },
        firstName: { type: 'string' },
        lastName: { type: 'string' },
        telephone: { type: 'number' },
        email: { type: 'string' },
        secondTelephone: { type: 'number' },
        city: { type: 'string' },
        street: { type: 'string' },
        streetNumber: { type: 'string' },
        apartmentNumber: { type: 'string' },
        profileImage: { type: 'string' },
        coordinates: { type: 'object' }, // Assuming google.maps.LatLng is an object
        isAppropriate: {
            type: 'object',
            properties: {
                level: { type: 'string' },
                note: { type: 'string' }
            }
        },
        lengthOfWritingBook: {
            type: 'object',
            properties: {
                length: { type: 'string' },
                note: { type: 'string' }
            }
        },
        startDate: {
            type: 'object',
            properties: {
                gregorianDate: { type: 'object' }, // Assuming CalendarDate is an object
                hebrewDateInWords: { type: 'string' }
            }
        },
        writingDeatails: {
            type: 'object',
            properties: {
                letterSizes: {
                    type: 'object',
                    // properties: {
                    //     17: { type: 'boolean' },
                    //     24: { type: 'boolean' },
                    //     30: { type: 'boolean' },
                    //     36: { type: 'boolean' },
                    //     40: { type: 'boolean' },
                    //     42: { type: 'boolean' },
                    //     45: { type: 'boolean' },
                    //     48: { type: 'boolean' },
                    //     note: { type: 'string' }
                    // }
                },
                writingLevel: {
                    type: 'object',
                    properties: {
                        level: { type: 'string' },
                        note: { type: 'string' }
                    }
                },
                stabilityLevel: {
                    type: 'object',
                    properties: {
                        level: { type: 'string' },
                        note: { type: 'string' }
                    }
                },
                eraseLevel: {
                    type: 'object',
                    properties: {
                        level: { type: 'string' },
                        note: { type: 'string' }
                    }
                },
                writingTypes: {
                    type: 'object',
                    properties: {
                        types: {
                            type: 'object',
                            properties: {
                                ari: { type: 'boolean' },
                                beitYosef: { type: 'boolean' },
                                Welish: { type: 'boolean' }
                            }
                        }
                    }
                }
            }
        },
        communityDeatails: {
            type: 'object',
            properties: {
                community: { type: 'string' },
                note: { type: 'string' }
            }
        },
        pricesDeatails: {
            type: 'object',
            properties: {
                isPricePerPage: { type: 'string' },
                priceForMezuzah: { type: 'object' }, // Assuming Price is an object
                priceForTefillin: { type: 'object' }, // Assuming Price is an object
                priceForTorahScroll: { type: 'object' }, // Assuming Price is an object
                priceForMegilla: { type: 'object' } // Assuming Price is an object
            }
        },
        placeOfWriting: {
            type: 'object',
            properties: {
                place: { type: 'string' },
                note: { type: 'string' }
            }
        },
        additionalDetails: {
            type: 'object',
            properties: {
                validCertificate: {
                    type: 'object',
                    properties: {
                        boolean: { type: 'string' },
                        note: { type: 'string' }
                    }
                },
                hasWritenBefore: {
                    type: 'object',
                    properties: {
                        boolean: { type: 'string' },
                        note: { type: 'string' }
                    }
                },
                hasWritenKabala: {
                    type: 'object',
                    properties: {
                        boolean: { type: 'string' },
                        note: { type: 'string' }
                    }
                },
                voatsInElection: {
                    type: 'object',
                    properties: {
                        boolean: { type: 'string' },
                        note: { type: 'string' }
                    }
                },
                goesToKotel: {
                    type: 'object',
                    properties: {
                        boolean: { type: 'string' },
                        note: { type: 'string' }
                    }
                },
                beginnerWriter: {
                    type: 'object',
                    properties: {
                        boolean: { type: 'string' },
                        note: { type: 'string' }
                    }
                },
                writerLevel: {
                    type: 'object',
                    properties: {
                        level: { type: 'number' },
                        note: { type: 'string' }
                    }
                }
            }
        },
        isWritingRegularly: {
            type: 'object',
            properties: {
                boolean: { type: 'string' }
            }
        },
        photos: { type: 'array', items: { type: 'string' } },
        recordings: { type: 'array', items: { type: 'string' } },
    },
    required: ['firstName', 'lastName', '_id', 'editorUserName', 'creationDate', 'editDate', 'note', 'levelOfUrgency', 'telephone', 'email', 'city', 'street', 'streetNumber', 'apartmentNumber', 'profileImage', 'coordinates', 'isAppropriate', 'lengthOfWritingBook', 'startDate', 'writingDeatails', 'communityDeatails', 'pricesDeatails', 'placeOfWriting', 'additionalDetails', 'isWritingRegularly', 'photos', 'recordings']
};

export const dealerSchema = {
    title: 'dealers schema',
    version: 0,
    description: 'describes a dealer',
    type: 'object',
    primaryKey: '_id',
    properties: {
        _id: { type: 'string', maxLength: 100 },
        firstName: { type: 'string' },
        lastName: { type: 'string' },
        city: { type: 'string' },
        books: { type: 'array', items: { type: 'string' } }
    },
    required: ['firstName', 'lastName', '_id']
};

export const bookSchema = {
    title: 'books schema',
    version: 0,
    description: 'describes a book',
    type: 'object',
    primaryKey: '_id',
    properties: {
        _id: { type: 'string', maxLength: 100 },
        name: { type: 'string' },
        communityDetails: { type: 'object' },
        writingDetails: { type: 'object' },
        photos: { type: 'array', items: { type: 'string' } },
        photos_620x620: { type: 'array', items: { type: 'string' } },
        recordings: { type: 'array', items: { type: 'string' } },
        levelOfUrgency: { type: 'number' },
        isSold: { type: 'boolean' }
    },
    required: ['name', '_id']
};

export const generalSchema = {
    title: 'general schema',
    version: 0,
    description: 'describes general items',
    type: 'object',
    primaryKey: '_id',
    properties: {
        _id: { type: 'string', maxLength: 100 },
        itemName: { type: 'string' },
        type: { type: 'string' }
    },
    required: ['itemName', 'type', '_id']
};

export const imgDocumentSchema = {
    title: 'imgDocument schema',
    version: 0,
    description: 'describes an image document',
    type: 'object',
    primaryKey: '_id',
    properties: {
        _id: { type: 'string', maxLength: 100},
        img: { type: 'blob' },
        folderName: { type: 'string' },
        fileName: { type: 'string' },
        extension: { type: 'string' },
        imgUrl: { type: 'string' },
        uploaded: { type: 'boolean' },
        uploadedTime: { type: 'number' },
        status: { type: 'string' },
        progress: { type: 'number' },
        _deleted: { type: 'boolean' }
    },
    required: ['img', 'folderName', 'fileName', 'extension', 'imgUrl', 'uploaded', 'uploadedTime', 'status', 'progress']
};

export interface ImgDocumentType {
    img: Blob;
    folderName: string;
    fileName: string;
    extension: string;
    imgUrl: string;
    uploaded: boolean;
    uploadedTime: number;
    status: 'pending' | 'uploading' | 'completed' | 'failed';
    progress: number;
    _deleted?: boolean;
}