import { Injectable } from '@angular/core';
import { RxCollection } from 'rxdb';
import { Book, Dealer } from './interfaces';
import { v4 as uuidv4 } from 'uuid';
import { UtilityService } from './utility.service';
import { DBService } from './stitch-service.service';

@Injectable({
  providedIn: 'root'
})
export class DealerService {
  private booksCollection: RxCollection<Book>;
  private dealersCollection: RxCollection<Dealer>;

  constructor(
    private readonly dbService: DBService,
    private readonly utilityService: UtilityService,
  ) {
    this.initCollections();
  }

  private async initCollections() {
    await this.dbService.dbReady;
    this.booksCollection = this.dbService.localBooksDB;
    this.dealersCollection = this.dbService.localDealersDB;
  }

  async removeBookFromDealer(dealerFullName: string, bookId: string) {
    await this.dbService.dbReady;
    try {
      const dealerList = await this.getDealersFullNameAndId();
      const dealerId = dealerList.find(dealer => dealer.fullName === dealerFullName)?._id;
      if (dealerId) {
        const dealer = await this.dealersCollection.findOne(dealerId).exec();
        if (dealer) {
          // Clone dealer object using JSON methods
          const dealerClone = JSON.parse(JSON.stringify(dealer));
          const booksArray: string[] = dealerClone.books || [];
          const index = booksArray.indexOf(bookId);
          if (index > -1) {
            booksArray.splice(index, 1);
          }
          await this.dealersCollection.upsert({ ...dealerClone, books: booksArray });
        } else {
          console.warn('Dealer not found for removing book:', dealerFullName);
        }
      } else {
        console.warn('Dealer ID not found for removing book:', dealerFullName);
      }
    } catch (error) {
      console.error('Error removing book from dealer:', error);
    }
  }

  async createDealer(dealer: Dealer) {
    await this.dbService.dbReady;
    try {
      await this.utilityService.createCity(dealer.city);
      const dealerClone = JSON.parse(JSON.stringify(dealer)) as Dealer;
      if (dealer._id) {
        await this.dealersCollection.upsert(dealerClone);
      } else {
        dealerClone._id = uuidv4();
        await this.dealersCollection.insert(dealerClone);
      }
    } catch (error) {
      console.error('Error creating dealer:', error);
    }
  }

  async addBookToDealer(bookId: string, dealerId: string) {
    await this.dbService.dbReady;
    try {
      const dealerDoc = await this.dealersCollection.findOne(dealerId).exec();
      if (dealerDoc) {
        // Clone dealer object using JSON methods
        const dealer = JSON.parse(JSON.stringify(dealerDoc));
        let bookArray = dealer.books ? dealer.books : [];
        bookArray.push(bookId);
        bookArray = [...new Set(bookArray)]; // Ensure no duplicates
        await this.dealersCollection.upsert({ ...dealer, books: bookArray });
      } else {
        console.warn('Dealer not found for adding book:', dealerId);
      }
    } catch (error) {
      console.error('Error adding book to dealer:', error);
    }
  }

  async getDealersFullNameAndId(): Promise<{ fullName: string, _id: string }[]> {
    await this.dbService.dbReady;
    try {
      const dealersList = await this.dealersCollection.find().exec();
      return dealersList.map(dealer => ({ fullName: `${dealer.lastName} ${dealer.firstName}`, _id: dealer._id }));
    } catch (error) {
      console.error('Error getting dealers full name and ID:', error);
      return [];
    }
  }

  async getDealerBookIds(dealerId: string): Promise<string[]> {
    await this.dbService.dbReady;
    try {
      const dealer = await this.dealersCollection.findOne(dealerId).exec();
      return dealer ? dealer.books : [];
    } catch (error) {
      console.error('Error getting dealer book IDs:', error);
      return [];
    }
  }

  async getDealerBooks(dealerId: string): Promise<Book[]> {
    await this.dbService.dbReady;
    try {
      const bookIds = await this.getDealerBookIds(dealerId);
      const books = await Promise.all(bookIds.map(async bookId => {
        const book = await this.booksCollection.findOne(bookId).exec();
        return book ? book.toJSON() : null;
      }));
      return books.filter(book => book !== null) as Book[];
    } catch (error) {
      console.error('Error getting dealer books:', error);
      return [];
    }
  }

  async getDealers(): Promise<Dealer[]> {
    await this.dbService.dbReady;
    try {
      const result = await this.dealersCollection.find().exec();
      return result.map(doc => doc.toJSON()) as Dealer[];
    } catch (error) {
      console.error('Error getting dealers:', error);
      return [];
    }
  }

  async getDealerById(id: string): Promise<Dealer | null> {
    await this.dbService.dbReady;
    try {
      const result = await this.dealersCollection.findOne(id).exec();
      return result ? result.toJSON() as Dealer : null;
    } catch (error) {
      console.error('Error getting dealer by ID:', error);
      return null;
    }
  }
}