import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class ExpandableListService {

    onToggleList = new Subject<void>();
}
